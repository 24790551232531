define("ember-svg-jar/inlined/arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M28.9 22.1L16 9 3.1 22.1 2 21 16 7l14 14-1.1 1.1z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});