define("ember-svg-jar/inlined/table-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"path-1-inside-1\" fill=\"white\"><rect width=\"16\" height=\"7\" rx=\"1\"/></mask><rect width=\"16\" height=\"7\" rx=\"1\" fill=\"none\" stroke-width=\"4\" stroke-linejoin=\"round\" mask=\"url(#path-1-inside-1)\"/><mask id=\"path-2-inside-2\" fill=\"white\"><rect y=\"9\" width=\"16\" height=\"7\" rx=\"1\"/></mask><rect y=\"9\" width=\"16\" height=\"7\" rx=\"1\" fill=\"none\" stroke-width=\"4\" stroke-linejoin=\"round\" mask=\"url(#path-2-inside-2)\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});