define("ember-svg-jar/inlined/market-data/hotels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n    <g transform=\"translate(2 2)\" fill=\"#225658\" fill-rule=\"evenodd\">\n        <circle stroke=\"#225658\" stroke-width=\"4\" fill-opacity=\".1\" cx=\"60\" cy=\"60\" r=\"60\"/>\n        <g fill-rule=\"nonzero\">\n            <path d=\"M39.75 83c.5 0 1-.25 1.25-.25 3.25-1 6-4 7-7.25h25.75c1 3.25 4 6.25 7 7.25.5.25 1 .25 1.25.25h1-43.25zm43.75 5c1.5 0 2.5-1 2.5-2.5v-5c0-1.5-1-2.5-2.5-2.5-2.25 0-5-2.75-5-5 0-1.5-1-2.5-2.5-2.5H46c-1.5 0-2.5 1-2.5 2.5 0 2.25-2.75 5-5 5-1.5 0-2.5 1-2.5 2.5v5c0 1.5 1 2.5 2.5 2.5h45zM48.5 53.75c0-7.5 5.5-13.25 12.5-13.25s12.5 5.75 12.5 13.25V63h-25v-9.25zm-5 11.75c0 1.5 1 2.5 2.5 2.5h30c1.5 0 2.5-1 2.5-2.5V53.75C78.5 43.5 71 35.5 61 35.5s-17.5 8-17.5 18.25V65.5z\"/>\n            <path d=\"M63.5 38v-7.5c0-1.5-1-2.5-2.5-2.5s-2.5 1-2.5 2.5V38c0 1.5 1 2.5 2.5 2.5s2.5-1 2.5-2.5z\"/>\n            <path d=\"M56 33h10c1.5 0 2.5-1 2.5-2.5S67.5 28 66 28H56c-1.5 0-2.5 1-2.5 2.5s1 2.5 2.5 2.5zM56 75.5h10c1.5 0 2.5-1 2.5-2.5v-7.5c0-1.5-1-2.5-2.5-2.5H56c-1.5 0-2.5 1-2.5 2.5V73c0 1.5 1 2.5 2.5 2.5zm2.5-7.5h5v2.5h-5V68zM56 53c0-2.75 2.25-5 5-5 1.5 0 2.5-1 2.5-2.5S62.5 43 61 43c-5.5 0-10 4.5-10 10 0 1.5 1 2.5 2.5 2.5s2.5-1 2.5-2.5z\"/>\n        </g>\n    </g>\n",
    "attrs": {
      "width": "124",
      "height": "124",
      "viewBox": "0 0 124 124",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});