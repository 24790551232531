define("ember-svg-jar/inlined/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.75 59.43c.98 0 1.894-.304 2.646-.82l9.8-6.122 20.8 13.867c.763.537 1.69.852 2.693.852 1.023 0 1.97-.33 2.742-.89h.002L83.997 47.57l-.002-.002a4.687 4.687 0 10-5.512-7.585l-23.87 16.85-20.72-13.814-.017.024a4.67 4.67 0 00-2.625-.803 4.66 4.66 0 00-2.45.693l-.002-.003-12.5 7.813.002.003a4.685 4.685 0 00-2.236 3.995 4.686 4.686 0 004.686 4.69zM95.312 0H4.688A4.687 4.687 0 000 4.688v90.625A4.686 4.686 0 004.688 100h90.625a4.687 4.687 0 004.688-4.688V4.688A4.687 4.687 0 0095.314 0zm-4.687 90.625H9.375V9.375h81.25v81.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});