define("ember-shepherd/utils/buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeButton = makeButton;

  /**
   * Creates a button of the specified type, with the given classes and text
   *
   * @param button.type The type of button cancel, back, or next
   * @param button.classes Classes to apply to the button
   * @param button.text The text for the button
   * @param button.action The action to call
   * @returns {{action: *, classes: *, text: *}}
   * @private
   */
  function makeButton(button) {
    var type = button.type,
        classes = button.classes,
        text = button.text;
    var builtInButtonTypes = ['back', 'cancel', 'next'];

    if (!type) {
      return button;
    }

    (false && !(builtInButtonTypes.includes(type)) && Ember.assert("'type' property must be one of 'back', 'cancel', or 'next'", builtInButtonTypes.includes(type)));
    var action = Ember.run.bind(this, function () {
      this[type]();
    });
    return {
      action: action,
      classes: classes,
      text: text
    };
  }
});