define("ember-svg-jar/inlined/logo-mini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24 8.4L13.2.5C12.9.2 12.5 0 12 0c-.4 0-.9.2-1.2.5L.5 8.7c-.5.5-.6 1.9-.4 2.5.3.7.9 1.8 1.6 1.8h2.8v6.8c0 1 .5 3.2 1.4 3.2h4.6v-7.7l-2.4-2.2c-.2-.2-.3-.5-.1-.8l6-5.9c.1-.1.2-.2.4-.2l4.7.2c.3 0 .5.2.5.5l.2 4.8c0 .1.1.3 0 .4l-5.3 5.4V23h4c1 0 2-2.2 2-3.2V13h2.2c.7 0 1.4-1.2 1.6-1.9.3-.7.2-2.2-.3-2.7zm-7.5-.7c-.5.5-.5 1.3 0 1.8s1.3.5 1.8 0 .5-1.3 0-1.8-1.3-.5-1.8 0z\"/><path opacity=\".35\" d=\"M13.3 18.2c-.1 0-.1-.1-.2-.2l-2.6-2.7V22l3.6-4.2-.3.2c-.1.1-.3.2-.5.2z\"/><path d=\"M14.2 17.5l-.2.3.2-.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24.5 23"
    }
  };
  _exports.default = _default;
});