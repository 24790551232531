define("ember-svg-jar/inlined/dynamic-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.333 6.051a.333.333 0 01.667 0v4.334H.333V6.05zM3 4.051a.333.333 0 11.667 0v6.334H3V4.05zM5.667 1.385a.333.333 0 11.667 0v9h-.667v-9zM8.333 6.72a.333.333 0 01.667 0v3.666h-.667V6.72zM11 4.72a.333.333 0 01.666 0v5.666H11V4.72z\" stroke-width=\".667\"/>",
    "attrs": {
      "width": "12",
      "height": "11",
      "viewBox": "0 0 12 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});