define("ember-svg-jar/inlined/booking-review-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M14.167 28.172a.622.622 0 01-.88-.03L1.696 15.71a.622.622 0 01.03-.88L16.113 1.417a.622.622 0 01.58-.147l9.74 2.54c.233.06.41.25.454.486l1.854 9.893a.622.622 0 01-.187.569L14.167 28.172zm6.465-21.335A2.073 2.073 0 1023.46 9.87a2.073 2.073 0 00-2.828-3.033z\" stroke=\"#2BA8B3\" stroke-width=\"1.244\" fill=\"#69C5CD\"/><text font-family=\"Europa-Bold, Europa\" font-size=\"11.609\" font-weight=\"bold\" fill=\"#FFF\" transform=\"rotate(47 14.65 21.55)\"><tspan x=\"6.613\" y=\"20.95\">$</tspan></text></g>",
    "attrs": {
      "viewBox": "0 0 30 29",
      "width": "30",
      "height": "29",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});