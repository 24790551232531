define("ember-data-model-fragments/transforms/array", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.array` that transforms array data with the given transform
    type.
  
    @class ArrayTransform
    @namespace MF
    @extends DS.Transform
  */
  var ArrayTransform = _transform.default.extend({
    store: Ember.inject.service(),
    type: null,
    deserialize: function deserializeArray(data) {
      if (data == null) {
        return null;
      }

      var transform = Ember.get(this, 'transform');
      data = Ember.makeArray(data);

      if (!transform) {
        return data;
      }

      return data.map(transform.deserialize, transform);
    },
    serialize: function serializeArray(array) {
      if (array == null) {
        return null;
      }

      var transform = Ember.get(this, 'transform');
      array = array.toArray ? array.toArray() : array;

      if (!transform) {
        return array;
      }

      return array.map(transform.serialize, transform);
    },
    transform: Ember.computed('type', function () {
      var attributeType = this.get('type');

      if (!attributeType) {
        return null;
      }

      var transform = Ember.getOwner(this).lookup("transform:".concat(attributeType));
      (false && !(!!transform) && Ember.assert("Unable to find transform for '".concat(attributeType, "'"), !!transform));
      return transform;
    })
  });

  var _default = ArrayTransform;
  _exports.default = _default;
});