define("ember-svg-jar/inlined/market-data/bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n    <g transform=\"translate(2 2)\" fill=\"#7EC5C4\" fill-rule=\"evenodd\">\n        <circle stroke=\"#7EC5C4\" stroke-width=\"4\" fill-opacity=\".1\" cx=\"60\" cy=\"60\" r=\"60\"/>\n        <g fill-rule=\"nonzero\">\n            <path d=\"M94.139 72.2v12.735h6.723v-19.04h-75.19V38.352A3.339 3.339 0 0 0 22.33 35C20.487 35 19 36.508 19 38.35v46.585h6.671V72.2H94.14z\"/>\n            <path d=\"M35.935 51.778a6.04 6.04 0 0 0 6.043-6.053 6.036 6.036 0 0 0-6.043-6.032 6.036 6.036 0 0 0-6.043 6.032 6.04 6.04 0 0 0 6.043 6.053z\"/>\n            <path d=\"M100.967 60.89l-.02-8.316c-.022-2.64-2.241-4.462-4.818-4.776l-47.47-4.734c-.093-.02-.177-.063-.26-.063a2.978 2.978 0 0 0-2.974 2.974v8.965H33.013a2.983 2.983 0 0 0-2.995 2.975 2.97 2.97 0 0 0 2.995 2.974h67.954z\"/>\n        </g>\n    </g>\n",
    "attrs": {
      "width": "124",
      "height": "124",
      "viewBox": "0 0 124 124",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});