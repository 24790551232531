define("ember-svg-jar/inlined/cards/card-unknown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <path d=\"M141.25 0H18.75C13.906 0 10.03 3.906 10 8.75v82.5c.062 4.844 3.906 8.75 8.75 8.75h122.5c4.875 0 8.75-3.97 8.75-8.844V8.844C150 3.97 146.125 0 141.25 0zM24.094 10h111.78c2.157 0 3.783 1.594 4.097 3.75V20H20v-6.344C20.312 11.53 21.97 10 24.094 10zm111.812 80H24.094c-2.156 0-3.78-1.53-4.094-3.656V50h120v36.25c-.312 2.156-1.97 3.75-4.094 3.75zM30 65h60v5H30v-5zm0 10h30v5H30v-5zm80-10h20v15h-20V65z\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "160",
      "height": "100",
      "viewBox": "0 0 160 100"
    }
  };
  _exports.default = _default;
});