define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M88.888 5.556H11.11C5 5.556 0 10.556 0 16.666v66.668c0 6.113 5 11.11 11.11 11.11h77.78c6.116 0 11.112-4.997 11.112-11.11V16.667c0-6.11-5-11.11-11.112-11.11zm0 77.778H11.11V32.64h77.78v50.694zm-42.43-10.468a2.893 2.893 0 01-2.305-1.147l-7.418-9.8a2.893 2.893 0 114.616-3.493l4.88 6.445L58.5 45.166a2.909 2.909 0 013.992-.93 2.896 2.896 0 01.923 3.99L48.913 71.502a2.873 2.873 0 01-2.322 1.36l-.13.003z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});