define("ember-svg-jar/inlined/icon-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.5 16C5.5 16 11 10.8269 11 6.61905C11 2.41116 8.5 0 5.5 0C2.5 0 0 2.41116 0 6.61905C0 10.8269 5.5 16 5.5 16ZM5.5 7.98448C7.01878 7.98448 8.25 6.79289 8.25 5.32298C8.25 3.85308 7.01878 2.66149 5.5 2.66149C3.98122 2.66149 2.75 3.85308 2.75 5.32298C2.75 6.79289 3.98122 7.98448 5.5 7.98448Z\" fill=\"#D8D8D8\"/>\n",
    "attrs": {
      "width": "11",
      "height": "16",
      "viewBox": "0 0 11 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});