define("ember-svg-jar/inlined/channel-logos/direct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n    <path d=\"M11.2 0L.1 11.1l4.7 4.7L0 20.5l11.1 11.1 15.8-15.8zM3.5 20.5l3-3 2 2a3.8 3.8 0 005.4 0l3.7-3.7-3.7-3.7a3.8 3.8 0 00-5.4 0l-2 2-2.9-2.9 7.7-7.7 12.3 12.3-12.5 12.3zm4.7-4.7l2-2a1.39 1.39 0 011.9 0l2 2-2 2a1.39 1.39 0 01-1.9 0z\" fill=\"#4ee2c1\"/>\n    <path d=\"M43.2 10.1h-5.1v11.4h5.1c3.6 0 6.1-2.4 6.1-5.7s-2.3-5.7-6.1-5.7zm0 8.9h-2.3v-6.3h2.3a3 3 0 013.3 3.2 3 3 0 01-3.3 3.1zm10.7-8.9h2.8v11.5h-2.8zm63.7 0v2.5h-4v8.9h-2.8v-8.9h-4.1v-2.5zm-45.4 4.3a4.1 4.1 0 00-4.1-4.3h-5.5v11.5h2.8v-2.8h2l1.7 2.8h3.4l-2.4-3.5a4.12 4.12 0 002.1-3.7zm-4.5 1.7h-2.4v-3.5h2.4a1.75 1.75 0 011.7 1.7 1.71 1.71 0 01-1.7 1.8zm26.5-.5a3.44 3.44 0 006.4 1.7l2.3 1.3a6.4 6.4 0 01-5.4 3 5.91 5.91 0 110-11.8 6.07 6.07 0 015.4 2.9l-2.3 1.4a3.53 3.53 0 00-3.1-1.7 3.07 3.07 0 00-3.3 3.2zm-16.3-5.5h8.8v2.5h-6.1v2h5.1V17h-5.1v2h6.1v2.5h-8.8z\" fill=\"#3f3f3f\"/>\n",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 117.6 31.6"
    }
  };
  _exports.default = _default;
});