define("ember-svg-jar/inlined/chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle stroke=\"#56C5CE\" stroke-width=\"5\" stroke-miterlimit=\"10\" cx=\"50.234\" cy=\"49.737\" r=\"46.5\" fill=\"none\"/><path fill=\"#56C5CE\" d=\"M74.045 32.701L62 55.322c-.445.729-1.321.847-1.943.264l-5.826-5.454c-.623-.583-1.52-.478-1.991.234l-8.178 9.335c-.472.711-1.374.823-2.005.248l-3.81-3.474a1.442 1.442 0 00-2.118.165L24.135 71.597c-.534.665-.273 1.211.579 1.211h50.144V32.931c-.001-.854-.366-.958-.813-.23z\"/>",
    "attrs": {
      "version": "1",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});