define("ember-svg-jar/inlined/relay/unmerged-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.9 2C8.9 5.1 7 9.4 7 14 7 9.4 5.1 5.1 2.1 2\" fill=\"none\" stroke-miterlimit=\"10\"/><path d=\"M0 0l3.9.4L.4 3.9 0 0zm13.6 3.9L10.1.4 14 0l-.4 3.9z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});