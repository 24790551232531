define("ember-svg-jar/inlined/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.1 3.1L9 16l13.1 12.9L21 30 7 16 21 2l1.1 1.1z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});