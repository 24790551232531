define("ember-svg-jar/inlined/onboarding/listing-placeholder-part-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M126 128H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h124c1.1 0 2 .9 2 2v124c0 1.1-.9 2-2 2zm178-92V10c0-1.1-.9-2-2-2H146c-1.1 0-2 .9-2 2v26c0 1.1.9 2 2 2h156c1.1 0 2-.9 2-2zm96 30V50c0-1.1-.9-2-2-2H146c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h252c1.1 0 2-.9 2-2zM292 92V76c0-1.1-.9-2-2-2H146c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h144c1.1 0 2-.9 2-2zm-20 26v-16c0-1.1-.9-2-2-2H146c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h124c1.1 0 2-.9 2-2z\"/>",
    "attrs": {
      "viewBox": "0 0 400 128"
    }
  };
  _exports.default = _default;
});