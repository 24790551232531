define("ember-svg-jar/inlined/channel-logos/hostfully", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n    <path d=\"M24.64 13.34L12.32 6.15 0 13.34V7.18L12.32 0l12.32 7.18v6.16z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#40caa1\"/>\n    <path d=\"M24.64 13.34L12.32 6.15V0l12.32 7.18v6.16z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#33ae8a\"/>\n    <path d=\"M16.38 22.36v-9.4l5.91 3.48v18.24h-5.91v-6.72H8.12v6.72H2.36V16.45l5.76-3.49v9.41l8.26-.01zm17.81 12.6c-5.4 0-9.67-4.18-9.67-9.53s4.28-9.53 9.67-9.53c5.4 0 9.67 4.18 9.67 9.53s-4.27 9.53-9.67 9.53zm0-5.58c2.26 0 3.89-1.62 3.89-3.95s-1.63-3.95-3.89-3.95c-2.25 0-3.89 1.62-3.89 3.95s1.64 3.95 3.89 3.95zm17.36-7.62c0 .39.48.66 1.72 1 .23.06 1.08.28 1.2.32.51.14.92.26 1.32.39 2.95.98 4.54 2.57 4.54 5.49 0 3.84-3.16 6-7.64 6-3.75 0-6.47-1.46-7.87-4.32l-.09-.18 5.04-2.84.08.24c.44 1.28 1.38 1.94 2.83 1.94 1.17 0 1.71-.31 1.71-.88 0-.36-.48-.64-1.67-.99-.26-.08-1.33-.37-1.22-.34-.53-.15-.95-.27-1.35-.41-2.95-1.01-4.54-2.53-4.54-5.27 0-3.71 3.04-6 7.21-6 3.05 0 5.65 1.38 7.15 3.88l.11.19-4.96 2.64-.1-.19c-.52-.96-1.21-1.44-2.2-1.44-.82 0-1.27.29-1.27.77zm22.1-5.36v5.51h-3.68v6.03c0 1.19.83 1.46 3.45 1.3l.23-.01v5.22l-.19.02c-6.8.69-9.27-1.19-9.27-6.53v-6.03h-2.86V16.4h2.86v-3.3l5.79-1.72v5.01l3.67.01zm13-7.55l.2.01v5.51l-.24-.02c-1.98-.18-3 .44-3.08 2.04h3.32v5.51h-3.32v12.56h-5.78V21.91h-2.39V16.4h2.39c.08-5.14 3.15-7.89 8.9-7.55zm9.22 26.11c-4 0-6.89-2.92-6.89-7.52V16.4h5.79v10.27c0 1.92 1.14 3 2.89 3 1.97 0 3.1-1.16 3.1-3.42V16.4h5.79v18.07h-5.79v-1.28c-1.1 1.12-2.82 1.77-4.89 1.77zm13.75-26.67h5.79v26.18h-5.79V8.29zm8.86 0h5.79v26.18h-5.79V8.29zm21 8.11h6.18l-.1.28-6.1 17.11c-2 5.62-5.22 7.98-10.39 7.73l-.21-.01v-5.35h.22c2.38 0 3.48-.7 4.23-2.71l-7.2-17.05h6.36l3.79 10.34 3.22-10.34z\" fill=\"#2d2aa5\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 145.65 41.54"
    }
  };
  _exports.default = _default;
});