define("ember-shepherd/services/tour", ["exports", "ember-shepherd/utils/attachTo", "ember-shepherd/utils/buttons", "ember-shepherd/utils/dom"], function (_exports, _attachTo, _buttons, _dom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    // Configuration Options
    confirmCancel: false,
    confirmCancelMessage: null,
    defaultStepOptions: {},
    disableScroll: false,
    errorTitle: null,
    isActive: false,
    messageForUser: null,
    modal: false,
    requiredElements: [],
    steps: [],
    willDestroy: function willDestroy() {
      this._cleanup();
    },

    /**
     * Get the tour object and call back
     * @public
     */
    back: function back() {
      Ember.get(this, 'tourObject').back();
      this.trigger('back');
    },

    /**
     * Cancel the tour
     * @public
     */
    cancel: function cancel() {
      Ember.get(this, 'tourObject').cancel();
    },

    /**
     * Complete the tour
     * @public
     */
    complete: function complete() {
      Ember.get(this, 'tourObject').complete();
    },

    /**
     * Hides the current step
     * @public
     */
    hide: function hide() {
      Ember.get(this, 'tourObject').hide();
    },

    /**
     * Advance the tour to the next step and trigger next
     * @public
     */
    next: function next() {
      Ember.get(this, 'tourObject').next();
      this.trigger('next');
    },

    /**
     * Show a specific step, by passing its id
     * @param id The id of the step you want to show
     * @public
     */
    show: function show(id) {
      Ember.get(this, 'tourObject').show(id);
    },

    /**
     * Start the tour
     * @public
     */
    start: function start() {
      Ember.set(this, 'isActive', true);
      Ember.get(this, 'tourObject').start();
    },

    /**
     * When the tour starts, setup the step event listeners, and disableScroll
     */
    onTourStart: function onTourStart() {
      if (Ember.get(this, 'disableScroll')) {
        disableScroll.on(window);
      }

      this.trigger('start');
    },

    /**
     * This function is called when a tour is completed or cancelled to initiate cleanup.
     * @param {string} completeOrCancel 'complete' or 'cancel'
     */
    onTourFinish: function onTourFinish(completeOrCancel) {
      if (!this.isDestroyed) {
        Ember.set(this, 'isActive', false);
      }

      this._cleanup();

      this.trigger(completeOrCancel);
    },

    /**
     * Cleanup disableScroll
     * @private
     */
    _cleanup: function _cleanup() {
      if (Ember.get(this, 'disableScroll')) {
        disableScroll.off(window);
      }
    },

    /**
     * Initializes the tour, creates a new Shepherd.Tour. sets options, and binds events
     * @private
     */
    _initialize: function _initialize() {
      var confirmCancel = Ember.get(this, 'confirmCancel');
      var confirmCancelMessage = Ember.get(this, 'confirmCancelMessage');
      var defaultStepOptions = Ember.get(this, 'defaultStepOptions');
      var tourName = Ember.get(this, 'tourName');
      var useModalOverlay = Ember.get(this, 'modal');
      var tourObject = new Shepherd.Tour({
        confirmCancel: confirmCancel,
        confirmCancelMessage: confirmCancelMessage,
        defaultStepOptions: defaultStepOptions,
        tourName: tourName,
        useModalOverlay: useModalOverlay
      });
      tourObject.on('start', Ember.run.bind(this, 'onTourStart'));
      tourObject.on('complete', Ember.run.bind(this, 'onTourFinish', 'complete'));
      tourObject.on('cancel', Ember.run.bind(this, 'onTourFinish', 'cancel'));
      Ember.set(this, 'tourObject', tourObject);
    },

    /**
     * Observes the array of requiredElements, which are the elements that must be present at the start of the tour,
     * and determines if they exist, and are visible, if either is false, it will stop the tour from executing.
     * @private
     */
    requiredElementsPresent: function requiredElementsPresent() {
      var _this = this;

      var allElementsPresent = true;
      var requiredElements = Ember.get(this, 'requiredElements');

      if (Ember.isPresent(requiredElements)) {
        /* istanbul ignore next: also can't test this due to things attached to root blowing up tests */
        requiredElements.forEach(function (element) {
          var selectedElement = document.querySelector(element.selector);

          if (allElementsPresent && (!selectedElement || (0, _dom.elementIsHidden)(selectedElement))) {
            allElementsPresent = false;
            Ember.set(_this, 'errorTitle', element.title);
            Ember.set(_this, 'messageForUser', element.message);
          }
        });
      }

      return allElementsPresent;
    },

    /**
     * Take a set of steps and create a tour object based on the current configuration
     * @param {Array} steps An array of steps
     * @private
     */
    addSteps: function addSteps(steps) {
      var _this2 = this;

      this._initialize();

      var tour = Ember.get(this, 'tourObject'); // Return nothing if there are no steps

      if (Ember.isEmpty(steps)) {
        return;
      }
      /* istanbul ignore next: also can't test this due to things attached to root blowing up tests */


      if (!this.requiredElementsPresent()) {
        tour.addStep('error', {
          buttons: [{
            text: 'Exit',
            action: tour.cancel
          }],
          title: Ember.get(this, 'errorTitle'),
          text: [Ember.get(this, 'messageForUser')]
        });
        return;
      }

      steps.forEach(function (step, index) {
        var id = step.id,
            options = step.options;

        if (options.buttons) {
          options.buttons = options.buttons.map(_buttons.makeButton.bind(_this2), _this2);
        }

        options.attachTo = (0, _attachTo.normalizeAttachTo)(options.attachTo);
        tour.addStep(id, options); // Step up events for the current step

        var currentStep = tour.steps[index];

        if (!currentStep.options.scrollToHandler) {
          currentStep.options.scrollToHandler = function (elem) {
            // Allow scrolling so scrollTo works.
            disableScroll.off(window);

            if (typeof elem !== 'undefined') {
              elem.scrollIntoView();
            }

            Ember.run.later(function () {
              if (Ember.get(_this2, 'disableScroll')) {
                disableScroll.on(window);
              }
            }, 50);
          };
        }
      });
    }
  });

  _exports.default = _default;
});