define("ember-svg-jar/inlined/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M283.675 292.581H307.2l-16.635-16.63c-8.97-8.965-14.57-20.627-16.076-33.279 23.405-15.354 40.52-35.555 49.681-58.722 9.155-23.15 10.306-48.633 3.327-73.696-8.374-30.074-28.013-57.484-55.3-77.184C242.543 11.663 205.63.348 165.444.348c-50.622 0-93.292 14.54-123.393 42.046C14.935 67.174 0 100.6 0 136.514c0 17.449 3.554 34.426 10.563 50.461 7.253 16.593 17.873 31.368 31.566 43.913 30.142 27.614 72.785 42.211 123.317 42.211 18.759 0 38.334-2.503 55.81-7.097 16.543 16.964 38.959 26.58 62.42 26.58zm-118.23-272.75c80.578 0 130.02 48.038 143.277 95.647 12.528 44.991-5.94 87.503-49.398 113.721l-4.856 2.93.15 5.667c.295 11.218 2.97 22.023 7.759 31.79-11.66-3.944-22.24-11.094-30.526-20.88l-4.12-4.865-6.109 1.826c-17.145 5.127-37.096 7.95-56.177 7.95-100.282 0-145.957-60.702-145.957-117.103 0-30.376 12.684-58.696 35.714-79.74 26.447-24.169 64.57-36.944 110.243-36.944z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 332 293",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});