define("ember-svg-jar/inlined/danger-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"icon-bg\" d=\"M32 2C15.4 2 2 15.4 2 32s13.4 30 30 30 30-13.4 30-30S48.6 2 32 2z\"/><path class=\"icon-fill\" d=\"M32 60C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28zm-2-13c.7.7 1.5 1 2.5 1s1.8-.3 2.5-1 1-1.5 1-2.5-.3-1.8-1-2.5-1.5-1-2.5-1-1.8.3-2.5 1-1 1.5-1 2.5c-.1 1 .3 1.8 1 2.5zm0-8.4h4.9l.2-22.1h-5.4l.3 22.1z\"/>",
    "attrs": {
      "viewBox": "0 0 64 64"
    }
  };
  _exports.default = _default;
});