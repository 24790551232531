define("ember-svg-jar/inlined/channel-logos/vreasy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n    <path d=\"M111.12 21.5c-1.7 0-2.4.5-3 2.1l-4.3 12.8c-.4 1.1-.7 1.1-.7 1.1h-.1s-.3-.1-.7-1.1l-4.7-11.7c-.7-1.3-1.6-1.8-2.7-2.1-1.2-.5-3.4-1-7.2-1-4.8 0-8.8 2.1-8.8 6.6 0 7.6 12.9 7.2 12.9 11.4 0 2.1-1.9 3-4.1 3h-10.5c-.7 0-.7 0-.7-1.8v-10c0-5.2-2.1-9.3-9.7-9.3-2.3 0-9.4 1.5-9.4 5.4v3.3s4.6-5.1 9.3-5c3.7 0 5.2 1.5 5.2 5.7v.2h-1c-3 0-9.6.3-12.7 3.7 0 0-8.2 7.7-12.3 7.7-4.2 0-7.9-2.9-8.1-7.9h17.3s.1-1.3.1-1.9c0-6.1-3.6-11.1-10.2-11.1-.4 0-.7 0-1.1.1h-10.3c-.4 0-.8.1-1.2.2-1 .2-1.7.7-2.2 1.5-.5.5-1.1 1.2-1.7 2.1v-.6c0-2-1-2.8-3.1-2.8h-3l4.5-15c.3-1.2 2.5-7.1 2.5-7.1h-3c-2.7 0-3.1 1-4 4.1l-8.4 28.7c-.8 2.5-1.5 6.4-1.5 6.4l-5.7-15.9c-.9-3.1-1.6-4.3-4.3-4.3H.02s-.1 1.9.2 3.1l10 24.4h4.6l6.2-20.5h1.8c.7 0 1.4.3 1.4 1.1v19.4h4.4v-10c0-1.5.2-4.4.2-4.4.6-3.3 2.4-5.5 4.1-6.8.3 0 2.1 0 3.3.1-2 2.1-3.1 5.1-3.1 8.7 0 7 5.1 12.4 12.6 12.4 3.9 0 8.6-3.5 11.1-5.6.8 3.7 4.3 5.6 7.9 5.6 5.7 0 7.5-4.7 7.5-4.7h.1s-.1.6-.1 1.4c0 1.7.8 3.3 2.9 3.3h12.4c5 0 8.7-2.7 8.7-6.9 0-7.9-12.9-6.9-12.9-11.4 0-1.9 1.8-2.5 4.4-2.8 4.3-.1 6 1.5 6.7 2.5l6.7 15.5-.9 2.3c-1.1 1.9-1.4 2.6-2.7 2.9-1.4.3-3.6.2-3.6.2-.9-.1-2.1 1.7-2.6 4l4.6.1c4.1 0 6.3-2.5 7.5-5.6l8.2-20.2c.3-.7 1.8-5.3 1.8-5.3l-2.3-.3zm-60.5 9.7h-12.6c.7-3.9 3.5-6 7-6 2.9 0 5.4 2.1 5.6 6m21.4 4c0 3.5-2.5 7.5-6.3 7.5-3 0-4.4-1.9-4.4-3.8 0-4.4 6.2-4.5 9.6-4.5h1.2v.8h-.1zm45.3-11.9c0 1-.8 1.7-1.8 1.7s-1.8-.8-1.8-1.7.8-1.7 1.8-1.7c1.1-.1 1.8.7 1.8 1.7m-3 0c0 .8.6 1.4 1.3 1.4.8 0 1.3-.6 1.3-1.4 0-.8-.6-1.4-1.3-1.4-.8 0-1.3.6-1.3 1.4m1 .9h-.4v-1.7c.2 0 .4-.1.7-.1s.5.1.6.1c.1.1.2.2.2.4s-.1.3-.4.4c.2.1.3.2.3.4.1.3.1.4.1.4h-.4c-.1-.1-.1-.2-.1-.4s-.1-.3-.4-.3h-.2v.8zm0-1h.2c.2 0 .4-.1.4-.3s-.1-.3-.4-.3h-.2v.6z\" fill=\"#40b655\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 117.32 52.9"
    }
  };
  _exports.default = _default;
});