define("ember-svg-jar/inlined/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 2.5h20m-20 7h20m-20 7h20\" stroke-width=\"2\" stroke-opacity=\".5\" stroke-linecap=\"round\"/><rect x=\"3\" width=\"5\" height=\"5\" rx=\"2.5\"/><rect x=\"13\" y=\"7\" width=\"5\" height=\"5\" rx=\"2.5\"/><rect x=\"6\" y=\"14\" width=\"5\" height=\"5\" rx=\"2.5\"/>",
    "attrs": {
      "width": "22",
      "height": "20",
      "viewBox": "0 0 22 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});