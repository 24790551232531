define("ember-svg-jar/inlined/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#3B5998\" d=\"M140 31.201h-28.4c-1.865 0-3.533 1-5 2.999-1.466 2-2.199 4.467-2.199 7.399V62H140v29.6h-35.602V180H70.4V91.6H40V62h30.4V44.8c0-12.534 3.933-23.134 11.799-31.8 7.867-8.667 17.667-13 29.4-13h28.399L140 31.201\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "180",
      "height": "180",
      "viewBox": "0 0 180 180"
    }
  };
  _exports.default = _default;
});