define("ember-svg-jar/inlined/money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle stroke=\"#56C5CE\" stroke-width=\"5\" stroke-miterlimit=\"10\" cx=\"50.234\" cy=\"50.312\" r=\"46.5\" fill=\"none\"/><path fill=\"#56C5CE\" d=\"M67.996 34.026a1.852 1.852 0 00-1.771-1.771l-16.479-.684a1.868 1.868 0 00-1.385.54L27.745 52.73a1.849 1.849 0 000 2.616L44.908 72.51a1.849 1.849 0 002.616 0l20.617-20.617c.365-.366.562-.868.541-1.387l-.686-16.48zM56.018 52.131l-2.751-1.968c1.122-1.076.81-2.104-.433-2.994-1.012-.725-2.25-1.099-3.063.037-.381.531-.423 1.226.954 2.641 2.731 2.785 2.517 4.62 1.524 6.004-2.022 2.819-4.841 3.004-7.337 1.353l-1.736 1.709L40.6 57.07l2.251-2.201a3.372 3.372 0 01-.341-1.208l-1.342.596-2.13-1.525 3.553-3.494 2.754 1.968c-1.104 1.088-.688 2.191.538 3.07 1.011.723 2.356 1.097 3.184-.06.343-.478.431-1.087-1.076-2.62-2.735-2.815-2.481-4.593-1.438-6.048 1.769-2.464 4.779-2.918 7.199-1.344l1.754-1.697 2.576 1.84-2.264 2.22c.15.351.246.741.282 1.141l1.358-.582 2.131 1.523-3.571 3.482z\"/>",
    "attrs": {
      "version": "1",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});