define("ember-svg-jar/inlined/loading_alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 19.5c-4.7 0-8.5-3.8-8.5-8.5S6.3 2.5 11 2.5s8.5 3.8 8.5 8.5-3.8 8.5-8.5 8.5zm0-15c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-2.9-6.5-6.5-6.5z\" opacity=\".33\"/><path d=\"M18.5 12c-.6 0-1-.4-1-1 0-3.6-2.9-6.5-6.5-6.5-1.1 0-2.3.3-3.2.9-.5.2-1.1.1-1.4-.4-.3-.5-.1-1.1.3-1.4C8 2.9 9.5 2.5 11 2.5c4.7 0 8.5 3.8 8.5 8.5 0 .6-.4 1-1 1z\"/>",
    "attrs": {
      "viewBox": "0 0 22 22"
    }
  };
  _exports.default = _default;
});