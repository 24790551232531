define("ember-svg-jar/inlined/icon-sub-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.69336 7.30824C5.69336 6.95323 5.83984 6.61396 6.09823 6.37051L9.96334 2.7289C10.4596 2.26137 11.2341 2.26137 11.7303 2.7289L15.5954 6.37052C15.8538 6.61396 16.0003 6.95323 16.0003 7.30824V12.2034C16.0003 12.6778 15.6158 13.0624 15.1414 13.0624H6.55227C6.07791 13.0624 5.69336 12.6778 5.69336 12.2034V7.30824Z\" style=\"fill:#2BA8B3\" fill=\"#2BA8B3\"/><path d=\"M0.573268 7.3481C0.573268 7.0989 0.670213 6.85948 0.843592 6.68048L4.98666 2.40322C5.68716 1.68003 6.85169 1.69488 7.53351 2.43569L11.4359 6.67564C11.5989 6.85278 11.6894 7.08472 11.6894 7.32547V13.3288C11.6894 13.5766 11.4885 13.7774 11.2408 13.7774H1.02189C0.774123 13.7774 0.573268 13.5766 0.573268 13.3288V7.3481Z\" style=\"fill:#A3E0E5\" fill=\"#A3E0E5\" stroke=\"white\" stroke-width=\"1.14654\"/>",
    "attrs": {
      "width": "16",
      "height": "15",
      "viewBox": "0 0 16 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});