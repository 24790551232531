define("ember-svg-jar/inlined/property-managers/sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <path d=\"M16.81 6.12c3.55 0 6.705 1.698 8.695 4.322l-3.79 3.838h11.88V2.253l-3.76 3.805C26.713 2.356 22.037.002 16.81.002 8.334.002 1.307 6.188.003 14.28h6.253c1.21-4.692 5.476-8.16 10.554-8.16z\" fill=\"#64C5CC\"/>\n  <path d=\"M16.81 27.88c-3.477 0-6.573-1.627-8.568-4.158l4.004-4.002H.012v12.23l3.88-3.88c3.122 3.63 7.75 5.928 12.918 5.928 8.476 0 15.502-6.186 16.807-14.278h-6.254c-1.21 4.692-5.476 8.16-10.553 8.16z\" fill=\"#8B8A86\"/>\n",
    "attrs": {
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});