define("ember-svg-jar/inlined/booking-mini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M81.3 100H0V18.7C0 8.4 8.4 0 18.7 0h62.7C91.6 0 100 8.4 100 18.7v62.7c0 10.2-8.4 18.6-18.7 18.6z\" fill=\"#273b7d\"/><path d=\"M65.3 68.7c0-3.3 2.6-5.9 5.9-5.9s5.9 2.6 5.9 5.9-2.6 5.9-5.9 5.9-5.9-2.6-5.9-5.9\" fill=\"#499fdd\"/><path d=\"M44.9 65.2h-8.1v-9.7c0-1.9.6-3.2 2.6-3.6h5.5c3.9 0 6.5 2.6 6.5 6.5 0 4.5-2.3 6.8-6.5 6.8zM36.8 39v-2.6c0-2.3 1-3.2 2.9-3.6h4.2c3.6 0 5.8 2.3 5.8 5.8 0 2.6-1.6 5.8-5.5 5.8h-7.4V39zm18.4 9.7l-1.3-.7 1.3-1c1.3-1.6 3.9-4.5 3.9-9.4 0-7.8-5.8-12.6-15.2-12.6H32.6c-2.6 0-4.9 2.3-4.9 5.2v43.3h16.8c10 0 16.8-5.5 16.8-14.2-.2-4.5-2.5-8.7-6.1-10.6\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});