define("ember-svg-jar/inlined/triangle-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M100 28.125c0 1.692-.62 3.157-1.854 4.396L54.394 76.27c-1.234 1.234-2.7 1.855-4.396 1.855-1.692 0-3.158-.617-4.396-1.854L1.854 32.52C.617 31.284 0 29.82 0 28.124c0-1.693.62-3.158 1.855-4.395 1.235-1.238 2.7-1.855 4.395-1.855h87.5c1.69 0 3.157.62 4.396 1.855 1.235 1.238 1.854 2.7 1.854 4.395z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});