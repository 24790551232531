define("ember-svg-jar/inlined/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M78.323 94.936a5.065 5.065 0 005.068 5.062h6.34a5.063 5.063 0 005.068-5.062v-50.73c0-2.8-2.27-5.062-5.068-5.062h-6.34a5.068 5.068 0 00-5.067 5.062v50.73zM53.805 74.27v20.666c0 2.8 2.275 5.062 5.084 5.062h6.323a5.067 5.067 0 005.066-5.062V74.27c0-2.8-2.276-5.075-5.067-5.075H58.89a5.084 5.084 0 00-5.085 5.076zm-24.51 7.613v13.055c0 2.8 2.263 5.062 5.07 5.062h6.33a5.068 5.068 0 005.068-5.062V81.883c0-2.8-2.277-5.09-5.07-5.09h-6.33c-2.804 0-5.068 2.29-5.068 5.09zM34.13 0C15.27 0 0 15.287 0 34.14c0 18.87 15.27 34.154 34.13 34.154 18.87 0 34.155-15.285 34.155-34.153C68.288 15.288 53 0 34.13 0zm.004 59.67c-14.097 0-25.51-11.425-25.51-25.527 0-14.092 11.414-25.518 25.51-25.518V34.2L54.898 49c-4.63 6.462-12.204 10.67-20.764 10.67z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 94.798 100"
    }
  };
  _exports.default = _default;
});