define("ember-google-maps/components/g-map/circle", ["exports", "ember-google-maps/components/g-map/marker"], function (_exports, _marker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Circle marker component.
   *
   * @class Circle
   * @namespace GMap
   * @module ember-google-maps/components/g-map/circle
   * @extends GMap.Marker
   */
  var _default = _marker.default.extend({
    _type: 'circle',
    radius: 500,
    center: Ember.computed.reads('position'),
    _createOptions: function _createOptions(options) {
      return _objectSpread({}, options, {
        map: this.map,
        radius: Ember.get(this, 'radius'),
        center: Ember.get(this, 'center')
      });
    },
    _addComponent: function _addComponent(options) {
      return Ember.RSVP.resolve(Ember.set(this, 'mapComponent', new google.maps.Circle(options)));
    }
  });

  _exports.default = _default;
});