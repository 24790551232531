define("ember-mapbox-gl/templates/components/mapbox-gl-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5CZCXWgS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[35,2]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"on\"],[[30,[36,0],[\"mapbox-gl-on\"],[[\"eventSource\"],[[32,0,[\"popup\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"domContent\",\"-clear-element\",\"in-element\"]}",
    "meta": {
      "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl-popup.hbs"
    }
  });

  _exports.default = _default;
});