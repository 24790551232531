define("ember-svg-jar/inlined/people-social/github", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <path d=\"M20.4,8C19,4,15.2,1.3,11,1.2c-5.5,0-10,4.5-10,10c0,4.3,2.8,8.1,6.8,9.5c0.5,0.1,0.7-0.2,0.7-0.5s0-1,0-1.9\n    c-2.5,0.5-3.2-0.6-3.4-1.2c-0.2-0.5-0.6-1-1-1.4c-0.3-0.2-0.8-0.6,0-0.7c0.6,0.1,1.2,0.4,1.5,1c0.6,1,1.8,1.4,2.9,0.8\n    c0-0.5,0.3-1,0.6-1.3c-2.2-0.2-4.5-1.1-4.5-4.9c0-1,0.3-2,1-2.7C5.4,7.1,5.4,6.2,5.8,5.3c0,0,0.8-0.3,2.8,1c1.6-0.5,3.4-0.5,5,0\n    c1.9-1.3,2.8-1,2.8-1c0.4,0.8,0.4,1.8,0.1,2.6c0.7,0.7,1,1.7,1,2.7c0,3.8-2.3,4.7-4.6,4.9c0.5,0.5,0.7,1.2,0.7,1.8\n    c0,1.3,0,2.4,0,2.8c0,0.3,0.2,0.6,0.7,0.5C19.4,18.9,22.2,13.2,20.4,8z\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 22",
      "enable-background": "new 0 0 22 22"
    }
  };
  _exports.default = _default;
});