define("ember-svg-jar/inlined/onboarding/listing-placeholder-part-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M94 79H2c-1.1 0-2-.9-2-2V51c0-1.1.9-2 2-2h92c1.1 0 2 .9 2 2v26c0 1.1-.9 2-2 2z\"/>",
    "attrs": {
      "viewBox": "0 0 96 128"
    }
  };
  _exports.default = _default;
});