define("ember-svg-jar/inlined/warning-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.4 11.1h-11c-.6 0-.9-.6-.6-1.1l5.5-9c.3-.5 1-.5 1.3 0l5.5 9c.2.5-.1 1.1-.7 1.1zm-5-6.8h-1v3.9h.9l.1-3.9zm-.5 4.3c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.3-.6-.6-.6z\"/>",
    "attrs": {
      "viewBox": "0 0 14 12"
    }
  };
  _exports.default = _default;
});