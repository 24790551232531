define("ember-svg-jar/inlined/channel-logos/myvr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n    <path d=\"M60.3 29.51c-.6 1.5-1.2 2.7-1.8 3.7s-1.3 1.8-2 2.4c-.7.6-1.6 1.1-2.4 1.4-.9.3-1.9.4-3 .4-1.3 0-2.5-.2-3.6-.5s-2.1-.7-3.1-1.2l2.2-4.8c.6.3 1.2.6 1.8.8s1.3.3 1.9.3c.9 0 1.7-.2 2.3-.7s1.2-1.3 1.7-2.6L42.6.61h6.9l8 20.9L64.8.61h6.7l-11.2 28.9zm-24-.8v-16c0-2.2-.5-3.9-1.5-5.1-1-1.2-2.4-1.8-4.2-1.8-1.8 0-3.2.6-4.3 1.8-1.1 1.2-1.7 2.9-1.7 5.2v15.8h-6.5v-16c0-2.2-.5-3.9-1.5-5-1-1.2-2.4-1.8-4.2-1.8-1.8 0-3.2.6-4.3 1.9-1.1 1.2-1.6 3-1.6 5.1v15.8H0v-28h6.5v4.3L8 3.11c.5-.6 1.2-1.1 1.8-1.6.7-.5 1.5-.8 2.3-1.1.9-.3 1.8-.4 2.9-.4 2 0 3.7.5 5.1 1.4s2.5 2.1 3.2 3.6c1.1-1.5 2.5-2.7 4-3.6s3.4-1.4 5.5-1.4c3.1 0 5.5.9 7.3 2.8 1.7 1.9 2.6 4.5 2.6 8v17.9h-6.4zm105.1-21.8c-1.4 0-2.7.2-3.9.7-1.2.5-2.2 1.2-3 2.1-.9.9-1.5 2.1-2 3.5s-.7 3-.7 4.9v10.7h-6.5V.61h6.5v6.3c.9-2.1 2.1-3.8 3.8-5.1 1.6-1.3 3.7-1.9 6.2-1.8v6.8c0 .1-.4.1-.4.1zm-30.8 22h-5.8L93.3.61h6.9l7.6 20.6 7.6-20.6h6.8l-11.6 28.3z\" fill=\"#264b63\"/>\n    <path d=\"M83.1 21.21v-6h5.5v6h-5.5zm-7.4-7.8v-6.2h5.7v6.2h-5.7zm0 7.8v-6h5.7v6h-5.7zm7.4-7.8v-6.2h5.5v6.2h-5.5z\" fill=\"#38a2d2\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 141.8 37.41"
    }
  };
  _exports.default = _default;
});