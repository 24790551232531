define("ember-svg-jar/inlined/link-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#56C5CE\" d=\"M41.3 41.1zM53 11.6l-8.9 8.9s7.9-1.7 14.7 3l3-3 .1-.1c3.2-3.2 8.4-3.2 11.6 0l5.9 5.9c3.2 3.2 3.4 8.5.2 11.8L58.9 58.7c-3.2 3.2-8.7 3.2-12 0l-5.8-5.8-8.8 8.8 5.8 5.8c2.1 2.1 4.6 3.7 7.3 4.7 7.5 2.9 16.4 1.3 22.4-4.7L88.4 47c8.1-8.1 8-21.3-.2-29.5l-5.9-5.9c-8.1-8.1-21.1-8.1-29.3 0zm1.7 16c-7.4-3-16.2-1.5-22.3 4.6L11.8 52.9c-8.1 8.1-8.3 21.3-.2 29.4l5.9 5.9c8.1 8.1 21.5 8.1 29.6 0l8.7-8.7s-7.9 1.6-14.7-2.9l-2.8 2.8c-3.2 3.2-8.7 3.2-12 0l-5.9-5.9c-3.2-3.2-3.1-8.5.2-11.8l20.5-20.5.1-.1c3.2-3.2 8.4-3.2 11.6 0l5.9 5.9.1.1 8.8-8.8-.1-.1-5.9-5.9c-2-2.1-4.4-3.7-6.9-4.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});