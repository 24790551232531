define("ember-svg-jar/inlined/pms-connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#264360\" fill-rule=\"evenodd\"><path d=\"M120 330H24c-13.234 0-24-10.766-24-24v-96c0-13.234 10.766-24 24-24h96c13.234 0 24 10.766 24 24v96c0 13.234-10.766 24-24 24zM24 202c-4.411 0-8 3.589-8 8v96c0 4.411 3.589 8 8 8h96c4.411 0 8-3.589 8-8v-96c0-4.411-3.589-8-8-8H24z\" fill-rule=\"nonzero\"/><circle cx=\"245\" cy=\"72\" r=\"28\"/><path d=\"M293 144h-96c-13.234 0-24-10.766-24-24V24c0-13.234 10.766-24 24-24h96c13.234 0 24 10.766 24 24v96c0 13.234-10.766 24-24 24zM197 16c-4.411 0-8 3.589-8 8v96c0 4.411 3.589 8 8 8h96c4.411 0 8-3.589 8-8V24c0-4.411-3.589-8-8-8h-96z\" fill-rule=\"nonzero\"/><path d=\"M97 266v-16c.021 0 35.611-.112 41.828-3.363C146.346 242.707 150 235.957 150 226V104c0-16.085 7.253-28.571 20.422-35.155C179.839 64.136 218.988 64 220 64l.044 16c-.066 0-36.655.112-42.873 3.363C169.654 87.293 166 94.043 166 104v122c0 16.085-7.253 28.571-20.422 35.155C136.161 265.864 98.012 266 97 266z\"/><circle cx=\"72\" cy=\"258\" r=\"28\"/></g>",
    "attrs": {
      "width": "317",
      "height": "330",
      "viewBox": "0 0 317 330"
    }
  };
  _exports.default = _default;
});