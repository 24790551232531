define("ember-svg-jar/inlined/arrow-round-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 8c0-4.4-3.6-8-8-8S0 3.6 0 8s3.6 8 8 8 8-3.6 8-8zm-7.8 3L5.8 8.6c-.3-.3-.3-.8 0-1.1L8.2 5c.3-.3.8-.3 1.1 0 .1 0 .2.1.2.2s.1.2.1.3 0 .2-.1.3c0 .1-.1.2-.2.3L7.5 8l1.9 1.9c.1.1.1.2.2.3 0 .1.1.2.1.3 0 .1 0 .2-.1.3 0 .1-.1.2-.2.3-.3.2-.9.2-1.2-.1z\" fill=\"#69c5cd\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});