define("ember-svg-jar/inlined/tokens", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4 4c-1.1 0-2 .9-2 2v3.15c0 .415.238.82.63.952A2.008 2.008 0 014 12c0 .88-.575 1.632-1.37 1.898-.392.131-.63.537-.63.952V18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-3.15c0-.415-.238-.82-.63-.952A2.008 2.008 0 0120 12c0-.88.575-1.632 1.37-1.898.392-.131.63-.537.63-.952V6c0-1.1-.9-2-2-2H4zm8 2.8l1.4 3.3 3.6.3-2.7 2.4.8 3.4-3.1-1.8-3.1 1.9.8-3.5L7 10.4l3.6-.3L12 6.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "96",
      "height": "96"
    }
  };
  _exports.default = _default;
});