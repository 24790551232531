define("ember-svg-jar/inlined/icon-back-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M8.30078 13.9707L2.00078 7.9857L8.30078 2.0007\" stroke=\"#BFBFBF\" stroke-width=\"2.01297\" stroke-linecap=\"square\"/>\n<line x1=\"3.13477\" y1=\"8.00195\" x2=\"17.1348\" y2=\"8.00195\" stroke=\"#BFBFBF\" stroke-width=\"2\"/>\n",
    "attrs": {
      "width": "18",
      "height": "16",
      "viewBox": "0 0 18 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});