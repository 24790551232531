define("ember-svg-jar/inlined/price-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path style=\"text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;marker:none;-inkscape-font-specification:Sans\" d=\"M37.813 6a2 2 0 00-1.126.469l-33 28.281A2 2 0 003 36.281V74a2 2 0 002 2h48.375C55.272 86.22 64.245 94 75 94c12.127 0 22-9.873 22-22s-9.873-22-22-22c-.673 0-1.342.034-2 .094V36.28a2 2 0 00-.688-1.531L69 31.906V14a2 2 0 00-2-2H51a2 2 0 00-.188 0A2 2 0 0049 14v.781L39.312 6.47a2 2 0 00-1.5-.469zM38 10.656L69 37.22v13.625C59.781 53.466 53 61.958 53 72h-4V46a2 2 0 00-2-2H29a2 2 0 00-.188 0A2 2 0 0027 46v26H7V37.219l31-26.563zM53 16h12v12.469L53 18.187V16zM31 48h14v24H31V48zm44 6c9.965 0 18 8.035 18 18s-8.035 18-18 18-18-8.035-18-18 8.035-18 18-18zm-.219 2.969A2 2 0 0073 59v2.219c-1.249.263-2.406.758-3.375 1.468C68.155 63.766 67 65.406 67 67.376c0 2.407 1.428 4.38 3.094 5.281.947.513 1.936.814 2.906 1.031v6.157c-1.73-.31-2.708-1.051-3.375-2a2 2 0 10-3.25 2.281c1.384 1.969 3.672 3.3 6.625 3.719V85a2 2 0 104 0v-1.188c1.04-.17 2.172-.467 3.281-1.03C82.166 81.823 84 79.8 84 77c0-2.44-1.394-4.438-3.063-5.375-1.283-.72-2.637-1.037-3.937-1.281v-5.188c1.061.159 1.883.467 2.5 1.156a2.003 2.003 0 003-2.656c-1.546-1.726-3.568-2.353-5.5-2.562V59a2 2 0 00-2.219-2.031zM73 65.406v4.125a5.466 5.466 0 01-1.031-.406c-.772-.418-.969-.541-.969-1.75 0-.342.265-.9 1-1.438.28-.205.629-.38 1-.53zm4 9.063c.826.191 1.546.401 2 .656.769.431 1 .603 1 1.875 0 1.26-.493 1.691-1.531 2.219-.432.22-.945.374-1.469.5v-5.25z\" font-weight=\"400\" color=\"#000\" stroke-width=\"4.718\" overflow=\"visible\" font-family=\"Sans\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});