define("ember-svg-jar/inlined/moon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.88579 9.71518C5.55496 11.0609 2.56964 10.2538 1.21789 7.91251C-0.133853 5.57122 0.659851 2.58232 2.99068 1.23661C3.9597 0.677148 5.04185 0.489772 6.0727 0.630036C5.90892 0.696669 5.74778 0.775279 5.59036 0.866162C3.7257 1.94273 3.09074 4.33385 4.17213 6.20688C5.25353 8.07992 7.64178 8.72558 9.50645 7.64902C9.66386 7.55813 9.81251 7.45788 9.95211 7.34937C9.55815 8.31224 8.85481 9.15572 7.88579 9.71518Z\" fill=\"#BFBFBF\"/>\n",
    "attrs": {
      "width": "10",
      "height": "11",
      "viewBox": "0 0 10 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});