define("ember-svg-jar/inlined/relay-stats-reservations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M14.1752 1.60474H12.41V0.731046C12.41 0.338777 12.089 0 11.6789 0C11.2688 0 10.9657 0.338777 10.9657 0.731046V1.60474H5.97318V0.731046C5.97318 0.338777 5.65224 0 5.24214 0C4.83204 0 4.51109 0.320947 4.51109 0.731046V1.60474H2.76371C1.2303 1.60474 0 2.85286 0 4.36845V13.8364C0 15.3698 1.24813 16.6001 2.76371 16.6001H14.1752C15.7086 16.6001 16.9389 15.352 16.9389 13.8364V4.36845C16.9389 2.85286 15.6907 1.60474 14.1752 1.60474ZM2.76371 3.049H4.52892V3.92269C4.52892 4.31496 4.84987 4.65373 5.25997 4.65373C5.67007 4.65373 5.99101 4.33279 5.99101 3.92269V3.049H10.9835V3.92269C10.9835 4.31496 11.3045 4.65373 11.7146 4.65373C12.1247 4.65373 12.4456 4.33279 12.4456 3.92269V3.049H14.1752C14.9062 3.049 15.4946 3.6374 15.4946 4.36845V6.06233H1.44426V4.36845C1.44426 3.6374 2.03267 3.049 2.76371 3.049ZM14.1752 15.1558H2.76371C2.03267 15.1558 1.44426 14.5674 1.44426 13.8364V7.52443H15.4946V13.8364C15.4946 14.5674 14.9062 15.1558 14.1752 15.1558Z\" fill=\"#43B6C0\"/>\n<path d=\"M3 10H4\" stroke=\"#43B6C0\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M8 10H9\" stroke=\"#43B6C0\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M13 10H14\" stroke=\"#43B6C0\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M3 13H4\" stroke=\"#43B6C0\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M8 13H9\" stroke=\"#43B6C0\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M13 13H14\" stroke=\"#43B6C0\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});