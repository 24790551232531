define("ember-svg-jar/inlined/cards/paypal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M71.1 29.2c.2-1.1.9-2 1.8-2.5.4-.2.9-.3 1.4-.3H95c2.5 0 4.7.2 6.8.5.6.1 1.2.2 1.7.3.6.1 1.1.3 1.6.4.3.1.5.2.8.2 1 .3 2 .7 2.9 1.2 1-6.6 0-11.1-3.6-15.2-3.9-4.5-11.1-6.4-20.2-6.4H58.7c-1.9 0-3.4 1.4-3.7 3.2L44 80.4c-.2 1.4.8 2.6 2.2 2.6h16.3l8.6-53.8\" fill=\"#27346a\"/><path d=\"M108.9 29.1l-.3 1.5C105.1 48.5 93.2 54.7 78 54.7h-7.8c-1.9 0-3.4 1.4-3.7 3.2l-5.1 32.3c-.2 1.2.7 2.3 2 2.3h13.7c1.6 0 3-1.2 3.3-2.8l.1-.7 2.6-16.4.2-.9c.3-1.6 1.6-2.8 3.3-2.8h2.1c13.3 0 23.7-5.4 26.8-21.1 1.3-6.5.6-12-2.8-15.8-1.1-1.2-2.3-2.1-3.8-2.9\" fill=\"#2790c3\"/><path d=\"M105.3 27.6c-.5-.2-1.1-.3-1.6-.4-.6-.1-1.1-.2-1.7-.3-2.1-.3-4.4-.5-6.8-.5H74.3c-.5 0-1 .1-1.4.3-1 .5-1.7 1.4-1.8 2.5l-4.4 27.9-.1.8c.3-1.8 1.9-3.2 3.7-3.2H78c15.2 0 27.1-6.2 30.6-24.1l.3-1.5c-.9-.5-1.8-.9-2.9-1.2-.2-.1-.5-.2-.7-.3\" fill=\"#1f264f\"/>",
    "attrs": {
      "width": "160",
      "viewBox": "0 0 160 100"
    }
  };
  _exports.default = _default;
});