define("ember-svg-jar/inlined/triangle-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M66.7 61.5c0 .6-.2 1.1-.6 1.5L51.5 77.5c-.4.4-.9.6-1.5.6s-1.1-.2-1.5-.6L34 62.9c-.4-.4-.6-.9-.6-1.5s.2-1.1.6-1.5c.4-.4.9-.6 1.5-.6h29.2c.6 0 1.1.2 1.5.6.3.5.5 1 .5 1.6z\"/><path class=\"st0\" d=\"M48 21.9h4v41.2h-4z\"/>",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});