define("ember-svg-jar/inlined/people-social/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <path d=\"M7.7,18.1c6.6,0,10.3-5.5,10.3-10.3c0-0.2,0-0.3,0-0.5c0.7-0.5,1.3-1.1,1.8-1.9c-0.6,0.3-1.3,0.5-2.1,0.6\n    c0.7-0.4,1.3-1.2,1.6-2C18.6,4.5,17.8,4.8,17,5c-0.7-0.7-1.6-1.1-2.6-1.1c-2,0-3.6,1.6-3.6,3.6c0,0.3,0,0.6,0.1,0.8\n    c-3-0.2-5.7-1.6-7.4-3.8C3.1,5,2.9,5.7,2.9,6.3c0,1.3,0.6,2.4,1.6,3C4,9.3,3.4,9.1,2.9,8.9c0,0,0,0,0,0c0,1.7,1.2,3.2,2.9,3.5\n    c-0.3,0.1-0.6,0.1-1,0.1c-0.2,0-0.5,0-0.7-0.1C4.6,14,6,15,7.5,15c-1.2,1-2.8,1.5-4.5,1.5c-0.3,0-0.6,0-0.9-0.1\n    C3.8,17.6,5.7,18.1,7.7,18.1\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 22",
      "enable-background": "new 0 0 22 22"
    }
  };
  _exports.default = _default;
});