define("ember-svg-jar/inlined/booking-review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M36.131 74.67a1.68 1.68 0 01-2.373-.083L2.458 41.02a1.68 1.68 0 01.082-2.373l38.842-36.22a1.68 1.68 0 011.57-.397l26.294 6.856a1.68 1.68 0 011.227 1.316l5.006 26.71a1.68 1.68 0 01-.506 1.537l-38.842 36.22zm17.456-57.604a5.597 5.597 0 107.634 8.187 5.597 5.597 0 00-7.634-8.187z\" stroke=\"#2BA8B3\" stroke-width=\"3.358\" fill=\"#69C5CD\"/><text font-family=\"Europa-Bold, Europa\" font-size=\"31.343\" font-weight=\"bold\" fill=\"#FFF\" transform=\"rotate(47 40.1 55.049)\"><tspan x=\"17.855\" y=\"55.164\">$</tspan></text></g>",
    "attrs": {
      "viewBox": "0 0 78 77",
      "width": "78",
      "height": "77",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});