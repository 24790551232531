define("ember-svg-jar/inlined/price-perfect-label-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><circle id=\"a\" cx=\"21\" cy=\"21\" r=\"21\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><circle fill=\"#264360\" mask=\"url(#b)\" cx=\"21\" cy=\"21\" r=\"21\"/><text mask=\"url(#b)\" font-family=\"Europa-Bold\" font-size=\"24\" font-weight=\"bold\" letter-spacing=\".45\" fill=\"#43B5BF\"><tspan x=\"14\" y=\"29\">$</tspan></text></g>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});