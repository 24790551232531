define("ember-svg-jar/inlined/bp-1property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"19.3\" cy=\"19.8\" r=\"12.6\" fill=\"#e4f1e0\" stroke=\"#231f20\" stroke-width=\".5\" stroke-miterlimit=\"10\"/><path d=\"M18.8 21.6c.2 0 .3-.1.3-.3s-.1-.3-.3-.3h-1.7c-.2 0-.3.1-.3.3s.1.3.3.3h1.7zM16.8 23c0-.2.1-.3.3-.3h1.7c.2 0 .3.1.3.3v4.2h-.6v-4h-1.1v4h-.6V23zM19.7 16.5h.6v1.7h-.6zM23.6 20.5h.6v6.9h-.6z\"/><path d=\"M15.7 20.8l2.3-2.4 2.3 2.4v6.5h.6v-6.6c0-.1 0-.1-.1-.2l-2.5-2.7c-.1-.1-.3-.1-.4 0l-2.5 2.7c0 .1-.1.1-.1.2v6.6h.6v-6.5h-.2z\"/><path d=\"M21.1 18.2l2.3 2.3h-3.1v.6h3.8c.3 0 .4-.3.2-.5l-2.8-2.8c-.1-.1-.1-.1-.2-.1H18v.6l3.1-.1z\"/><g><path d=\"M27.8 28.9c-.1 0-.1 0-.2-.1-.8-.8-4.3-1.4-8.4-1.4s-7.6.6-8.4 1.4c-.1.1-.3.1-.4 0-.1-.1-.1-.3 0-.4 1.2-1.2 5.5-1.6 8.8-1.6s7.6.4 8.8 1.6c.1.1.1.3 0 .4 0 .1-.1.1-.2.1z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 38.3 38.3"
    }
  };
  _exports.default = _default;
});