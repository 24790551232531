define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#010101\" d=\"M41.253 41.069l-.092.092.092-.092zm11.785-29.463l-8.931 8.931s7.918-1.657 14.658 3.02l3.02-3.02.092-.092c3.241-3.241 8.36-3.241 11.601 0l5.893 5.893c3.242 3.241 3.426 8.545.185 11.786L58.931 58.747c-3.24 3.241-8.729 3.241-11.97 0l-5.801-5.801-8.839 8.839 5.801 5.801a21.014 21.014 0 007.256 4.715c7.514 2.871 16.371 1.307 22.392-4.715l20.624-20.624c8.141-8.14 7.955-21.324-.184-29.463l-5.893-5.893c-8.138-8.139-21.138-8.14-29.279 0zm1.676 16.002c-7.439-2.983-16.223-1.455-22.3 4.623L11.79 52.854c-8.139 8.139-8.322 21.323-.184 29.462l5.893 5.893c8.139 8.14 21.508 8.14 29.646.001l8.747-8.747s-7.882 1.621-14.749-2.928l-2.837 2.836c-3.241 3.24-8.728 3.24-11.97 0l-5.893-5.893c-3.24-3.241-3.057-8.545.185-11.785L41.16 41.161l.092-.092c3.24-3.241 8.359-3.241 11.601 0l5.893 5.893.093.092 8.838-8.839-.092-.092-5.893-5.893a20.902 20.902 0 00-6.978-4.622z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});