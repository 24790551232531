define("ember-svg-jar/inlined/more-table-view-opts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#43B6C0\" d=\"M1.5 0C2.3 0 3 .7 3 1.5S2.3 3 1.5 3 0 2.3 0 1.5.7 0 1.5 0zm0 5C2.3 5 3 5.7 3 6.5S2.3 8 1.5 8 0 7.3 0 6.5.7 5 1.5 5zm0 5c.8 0 1.5.7 1.5 1.5S2.3 13 1.5 13 0 12.3 0 11.5.7 10 1.5 10z\"/>",
    "attrs": {
      "viewBox": "0 0 3 13"
    }
  };
  _exports.default = _default;
});