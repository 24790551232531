define("ember-svg-jar/inlined/network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M90.887 44.725c-4.076 0-7.434 2.637-8.632 6.234l-14.15-1.44c-.12-5.635-2.757-10.55-6.954-13.668l9.475-16.067c.84.24 1.798.48 2.877.48a9.109 9.109 0 009.113-9.112 9.11 9.11 0 00-9.113-9.113 9.109 9.109 0 00-9.112 9.113c0 2.637 1.08 4.916 2.878 6.595l-9.473 16.066c-2.158-.96-4.557-1.56-7.194-1.56-4.316 0-8.152 1.56-11.27 4.078l-8.154-7.674c.84-1.318 1.32-2.998 1.32-4.677a9.109 9.109 0 00-9.114-9.11 9.107 9.107 0 00-9.112 9.11 9.11 9.11 0 009.112 9.115c1.918 0 3.597-.6 5.156-1.56l8.153 7.674a17.584 17.584 0 00-3.476 10.43c0 1.2.12 2.52.36 3.598L16.91 58.873a9.02 9.02 0 00-7.794-4.437C4.077 54.437 0 58.514 0 63.55s4.077 9.112 9.113 9.112 9.113-4.077 9.113-9.113v-.96l16.667-5.636c2.758 5.995 8.753 10.072 15.827 10.072.72 0 1.32 0 1.92-.12l3.116 13.79C52.64 82.134 50.6 85.252 50.6 88.85a9.108 9.108 0 009.113 9.11c5.036 0 9.113-4.075 9.113-9.11s-4.077-9.114-9.113-9.114h-.12l-3.116-13.79a17.702 17.702 0 0011.27-12.828l14.148 1.438c.48 4.558 4.317 8.034 8.993 8.034a9.108 9.108 0 009.113-9.112c-.12-4.677-4.196-8.753-9.113-8.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});