define("ember-svg-jar/inlined/availability-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M255.8 128c0 70.6-57.4 128-128 128-38.7 0-74.4-17.3-98.4-46.1L7.5 224l-3.8-78.6 69.9 36L54.1 194c18.6 20.7 45.3 33 73.7 33 54.6 0 99-44.4 99-99.1s-44.4-99.1-99-99.1c-49.3 0-91.5 36.8-98.1 85.7l-.9 6.7-28.6-5.1.9-6.1c4.3-30.2 19.3-58 42.5-78.3C66.9 11.3 96.8 0 127.8 0c70.6 0 128 57.4 128 128zm-139.5 55.8L193 87.2l-26.6-21.1-55.5 69.9-29-23-21.1 26.6 55.5 44.2z\"/>",
    "attrs": {
      "viewBox": "0 0 256 256"
    }
  };
  _exports.default = _default;
});