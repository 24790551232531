define("ember-svg-jar/inlined/icon-caret", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M7 4.5L0.25 8.39711L0.25 0.602886L7 4.5Z\" fill=\"#69C5CD\"/>\n",
    "attrs": {
      "width": "7",
      "height": "9",
      "viewBox": "0 0 7 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});