define("ember-svg-jar/inlined/property-managers/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <path d=\"M0 11.636V33.42c0 1.626 1.303 2.944 2.91 2.944h26.18c1.607 0 2.91-1.318 2.91-2.944V11.636H0zm8 22.546H2.182v-5.818H8v5.818zm0-7.273H2.182v-5.82H8v5.82zm0-7.275H2.182v-5.818H8v5.818zm7.273 14.546H9.455v-5.817h5.818v5.818zm0-7.272H9.455v-5.82h5.818v5.82zm0-7.274H9.455v-5.818h5.818v5.818zm7.272 14.546h-5.818v-5.818h5.818v5.818zm0-7.273h-5.818v-5.82h5.818v5.82zm0-7.274h-5.818v-5.818h5.818v5.818zm7.273 14.546H24v-5.82h5.818v5.82zm0-7.274H24v-5.82h5.818v5.82zm0-7.274H24v-5.818h5.818v5.818z\" fill=\"#8B8A86\"/>\n  <path d=\"M29.09 4.364h-4.726V1.09c0-.602-.49-1.09-1.09-1.09-.604 0-1.092.488-1.092 1.09v3.274H9.818V1.09c0-.6-.49-1.09-1.09-1.09-.603 0-1.092.488-1.092 1.09v3.274H2.91C1.302 4.364 0 5.68 0 7.307v2.875h32V7.308c0-1.627-1.303-2.944-2.91-2.944zM8.728 9.454c-1.204 0-2.182-.977-2.182-2.18 0-.808.44-1.51 1.09-1.89v1.89c0 .6.49 1.09 1.092 1.09.602 0 1.09-.49 1.09-1.09v-1.89c.653.38 1.092 1.082 1.092 1.89 0 1.203-.978 2.18-2.183 2.18zm14.546 0c-1.205 0-2.182-.977-2.182-2.18 0-.808.44-1.51 1.092-1.89v1.89c0 .6.488 1.09 1.09 1.09.603 0 1.092-.49 1.092-1.09v-1.89c.65.38 1.09 1.082 1.09 1.89 0 1.203-.976 2.18-2.18 2.18z\" fill=\"#64C5CC\"/>\n",
    "attrs": {
      "width": "32",
      "height": "37",
      "viewBox": "0 0 32 37",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});