define("ember-svg-jar/inlined/bp-pricing-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.383 10.117c.184-.2.284-.45.284-.75v-7.95c0-.284-.1-.567-.317-.75A1.09 1.09 0 0 0 18.6.35h-7.967a.89.89 0 0 0-.416.083C10.083.5 9.95.583 9.833.7L.667 9.85c-.434.433-.434 1.117 0 1.517l7.983 7.966c.433.434 1.117.434 1.517 0l9.2-9.2c0 .017.016-.016.016-.016zm-1.25-8.234c.5.5.5 1.334 0 1.834s-1.333.5-1.833 0-.5-1.334 0-1.834 1.333-.5 1.833 0zm-7.5 10.6l-3.533.934c-.333.083-.617-.2-.517-.517l.934-3.533c.083-.334.483-.434.733-.2l2.6 2.6c.233.25.1.633-.217.716zm3.867.75L6.783 5.517 10.75 1.55a.727.727 0 0 1 .167-.1c.066-.017.1-.033.166-.033h4.15a2.433 2.433 0 0 0 .284 3.116c.85.85 2.166.934 3.116.267v4.15a.473.473 0 0 1-.15.317v.016l-3.983 3.95z\" fill=\"#1ECAD3\"/><path d=\"M14.5 13.233L6.783 5.517 10.75 1.55a.73.73 0 0 1 .167-.1c.066-.017.1-.033.166-.033h4.15a2.433 2.433 0 0 0 .284 3.116c.85.85 2.166.934 3.116.267v4.15a.474.474 0 0 1-.15.317v.016l-3.983 3.95z\" fill=\"#E5F6F7\"/><path d=\"M14.717 5.85c.083-.333-.2-.617-.517-.517l-3.533.934c-.334.083-.434.483-.2.733l2.566 2.567c.25.25.65.116.734-.2l.95-3.517z\" fill=\"#1ECAD3\"/><path d=\"M10.633 12.483l-3.533.934c-.333.083-.617-.2-.517-.517l.934-3.533c.083-.334.483-.434.733-.2l2.6 2.6c.233.25.1.633-.217.716z\" fill=\"#E5F6F7\"/>",
    "attrs": {
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});