define("ember-data-model-fragments/array/fragment", ["exports", "ember-data-model-fragments/array/stateful", "ember-data-model-fragments/fragment", "ember-data-model-fragments/util/instance-of-type"], function (_exports, _stateful, _fragment, _instanceOfType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    @module ember-data-model-fragments
  */
  // Normalizes an array of object literals or fragments into fragment instances,
  // reusing fragments from a source content array when possible
  function normalizeFragmentArray(array, content, objs, canonical) {
    var record = Ember.get(array, 'owner');
    var store = Ember.get(record, 'store');
    var declaredModelName = Ember.get(array, 'type');
    var options = Ember.get(array, 'options');
    var key = Ember.get(array, 'name');
    var fragment;
    return objs.map(function (data, index) {
      var type = Ember.get(array, 'type');
      (false && !(Ember.typeOf(data) === 'object' || (0, _instanceOfType.default)(store.modelFor(type), data)) && Ember.assert("You can only add '".concat(type, "' fragments or object literals to this property"), Ember.typeOf(data) === 'object' || (0, _instanceOfType.default)(store.modelFor(type), data)));

      if ((0, _fragment.isFragment)(data)) {
        fragment = data;

        var owner = (0, _fragment.internalModelFor)(fragment)._recordData.getOwner();

        (false && !(!owner || owner === record) && Ember.assert('Fragments can only belong to one owner, try copying instead', !owner || owner === record));

        if (!owner) {
          (0, _fragment.setFragmentOwner)(fragment, record, key);
        }
      } else {
        fragment = content[index];

        if (fragment) {
          // The data could come from a property update, which should leave the
          // fragment in a dirty state, or an adapter operation which should leave
          // it in a clean state
          if (canonical) {
            (0, _fragment.setFragmentData)(fragment, data);
          } else {
            Ember.setProperties(fragment, data);
          }
        } else {
          fragment = (0, _fragment.createFragment)(store, declaredModelName, record, key, options, data);
        }
      }

      return fragment;
    });
  }
  /**
    A state-aware array of fragments that is tied to an attribute of a `DS.Model`
    instance. `FragmentArray` instances should not be created directly, instead
    use `MF.fragmentArray` or `MF.array`.
  
    @class FragmentArray
    @namespace MF
    @extends StatefulArray
  */


  var FragmentArray = _stateful.default.extend({
    /**
      The type of fragments the array contains
       @property type
      @private
      @type {String}
    */
    type: null,
    options: null,

    /**
      @method _normalizeData
      @private
      @param {Object} data
    */
    _normalizeData: function _normalizeData(data) {
      var content = Ember.get(this, 'content');
      return normalizeFragmentArray(this, content, data, true);
    },

    /**
      @method _createSnapshot
      @private
    */
    _createSnapshot: function _createSnapshot() {
      // Snapshot each fragment
      return this.map(function (fragment) {
        return fragment._createSnapshot();
      });
    },

    /**
      @method _flushChangedAttributes
    */
    _flushChangedAttributes: function _flushChangedAttributes() {
      this.map(function (fragment) {
        fragment._flushChangedAttributes();
      });
    },

    /**
      @method _didCommit
      @private
    */
    _didCommit: function _didCommit(data) {
      this._super.apply(this, arguments); // Notify all records of commit; if the adapter update did not contain new
      // data, just notify each fragment so it can transition to a clean state


      this.forEach(function (fragment, index) {
        fragment._didCommit(data && data[index]);
      });
    },

    /**
      @method _adapterDidError
      @private
    */
    _adapterDidError: function _adapterDidError(error) {
      this._super.apply(this, arguments); // Notify all records of the error; if the adapter update did not contain new
      // data, just notify each fragment so it can transition to a clean state


      this.forEach(function (fragment) {
        fragment._adapterDidError(error);
      });
    },

    /**
      If this property is `true`, either the contents of the array do not match
      its original state, or one or more of the fragments in the array are dirty.
       Example
       ```javascript
      array.toArray(); // [ <Fragment:1>, <Fragment:2> ]
      array.get('hasDirtyAttributes'); // false
      array.get('firstObject').set('prop', 'newValue');
      array.get('hasDirtyAttributes'); // true
      ```
       @property hasDirtyAttributes
      @type {Boolean}
      @readOnly
    */
    hasDirtyAttributes: Ember.computed('@each.hasDirtyAttributes', '_originalState', function () {
      return this._super.apply(this, arguments) || this.isAny('hasDirtyAttributes');
    }),

    /**
      This method reverts local changes of the array's contents to its original
      state, and calls `rollbackAttributes` on each fragment.
       Example
       ```javascript
      array.get('firstObject').get('hasDirtyAttributes'); // true
      array.get('hasDirtyAttributes'); // true
      array.rollbackAttributes();
      array.get('firstObject').get('hasDirtyAttributes'); // false
      array.get('hasDirtyAttributes'); // false
      ```
       @method rollbackAttributes
    */
    rollbackAttributes: function rollbackAttributes() {
      this._super.apply(this, arguments);

      this.invoke('rollbackAttributes');
    },

    /**
      Serializing a fragment array returns a new array containing the results of
      calling `serialize` on each fragment in the array.
       @method serialize
      @return {Array}
    */
    serialize: function serialize() {
      return this.invoke('serialize');
    },

    /**
      Used to normalize data since all array manipulation methods use this method.
       @method replaceContent
      @private
    */
    replaceContent: function replaceContent(index, amount, objs) {
      var content = Ember.get(this, 'content');
      var replacedContent = content.slice(index, index + amount);
      var fragments = normalizeFragmentArray(this, replacedContent, objs);
      return content.replace(index, amount, fragments);
    },

    /**
      Adds an existing fragment to the end of the fragment array. Alias for
      `addObject`.
       @method addFragment
      @param {MF.Fragment} fragment
      @return {MF.Fragment} the newly added fragment
    */
    addFragment: function addFragment(fragment) {
      return this.addObject(fragment);
    },

    /**
      Removes the given fragment from the array. Alias for `removeObject`.
       @method removeFragment
      @param {MF.Fragment} fragment
      @return {MF.Fragment} the removed fragment
    */
    removeFragment: function removeFragment(fragment) {
      return this.removeObject(fragment);
    },

    /**
      Creates a new fragment of the fragment array's type and adds it to the end
      of the fragment array
       @method createFragment
      @param {MF.Fragment} fragment
      @return {MF.Fragment} the newly added fragment
      */
    createFragment: function createFragment(props) {
      var record = Ember.get(this, 'owner');
      var store = Ember.get(record, 'store');
      var type = Ember.get(this, 'type');
      var fragment = store.createFragment(type, props);
      return this.pushObject(fragment);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments); // destroy the current state


      this.forEach(function (fragment) {
        fragment.destroy();
      }); // destroy the original state

      this._originalState.forEach(function (fragment) {
        fragment.destroy();
      });
    }
  });

  var _default = FragmentArray;
  _exports.default = _default;
});