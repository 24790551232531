define("ember-svg-jar/inlined/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 2a1.5 1.5 0 00-1.5 1.5v.695A5.997 5.997 0 006 10v6l-1.535 1.156h-.002A1 1 0 004 18a1 1 0 001 1h14a1 1 0 001-1 1 1 0 00-.463-.844L18 16v-6a5.997 5.997 0 00-4.5-5.805V3.5A1.5 1.5 0 0012 2zm-2 18c0 1.1.9 2 2 2s2-.9 2-2h-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "width": "96",
      "height": "96"
    }
  };
  _exports.default = _default;
});