define("ember-svg-jar/inlined/icon-bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.07578 0.569336H2.86875V2.39966H12.1922V0.569336H13.9852V6.42637H13.4982C14.4294 6.93557 15.0609 7.92418 15.0609 9.0603V10.4531V10.8191V13.7477H13.268V10.8191H1.79297V13.7477H0V10.8191V10.4531V9.0603C0 7.92418 0.631542 6.93557 1.56272 6.42637H1.07578V0.569336ZM8.42695 4.22998H12.1922V6.06312C12.1487 6.06125 12.1049 6.0603 12.0609 6.0603H8.42695V4.22998ZM6.63398 4.22998H2.86875V6.06312C2.91227 6.06125 2.95602 6.0603 3 6.0603H6.63398V4.22998Z\" fill=\"#D8D8D8\"/>\n",
    "attrs": {
      "width": "16",
      "height": "14",
      "viewBox": "0 0 16 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});