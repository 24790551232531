define("ember-svg-jar/inlined/bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 6V0h-2v2H3V0H1v6c-.6 0-1 .4-1 1v7h2v-3h12v3h2V7c0-.6-.4-1-1-1zm-2 0H9V4h4v2zM3 4h4v2H3V4z\"/>",
    "attrs": {
      "viewBox": "0 0 16 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});