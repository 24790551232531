define("ember-google-maps/components/g-map/autocomplete", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/autocomplete", "ember-google-maps/utils/options-and-events"], function (_exports, _mapComponent, _autocomplete, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AutocompleteAPI = AutocompleteAPI;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function AutocompleteAPI(source) {
    var mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      get place() {
        return source.place;
      }

    });
  }
  /**
   * @class Autocomplete
   * @namespace GMap
   * @module ember-google-maps/components/g-map/autocomplete
   * @extends GMap.MapComponent
   */


  var _default = _mapComponent.default.extend({
    layout: _autocomplete.default,
    tagName: 'div',
    _type: 'autocomplete',
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat(_toConsumableArray(_optionsAndEvents.ignoredOptions), ['onSearch'])),
    init: function init() {
      this._super.apply(this, arguments);

      this.publicAPI = AutocompleteAPI(this);
    },
    _addComponent: function _addComponent(options) {
      var map = Ember.get(this, 'map');
      var inputElement = this.element.querySelector('input');
      (false && !(inputElement) && Ember.assert('You must define your own input within the ember-google-maps autocomplete block.', inputElement));
      var autocomplete = new google.maps.places.Autocomplete(inputElement, options);
      Ember.set(this, 'mapComponent', autocomplete); // Bias the search results to the current map bounds.

      autocomplete.setBounds(map.getBounds());
      map.addListener('bounds_changed', function () {
        autocomplete.setBounds(map.getBounds());
      });
      autocomplete.addListener('place_changed', this._onSearch.bind(this));
      return Ember.RSVP.resolve(this.mapComponent);
    },
    _onSearch: function _onSearch() {
      this.place = this.mapComponent.getPlace();

      if (this.place.geometry) {
        Ember.tryInvoke(this, 'onSearch', [this.publicAPI]);
      }
    }
  });

  _exports.default = _default;
});