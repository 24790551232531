define("ember-mapbox-gl/templates/components/mapbox-gl-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oercb8k4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"layer\"],[[30,[36,2],[\"mapbox-gl-layer\"],[[\"map\",\"_sourceId\"],[[35,1],[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sourceId\",\"map\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl-source.hbs"
    }
  });

  _exports.default = _default;
});