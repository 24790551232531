define("ember-svg-jar/inlined/binoculars-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M90.891 41.084L68.135 22.368c-5.861-5.056-14.996-3.03-18.136 4.058-2.685-6.045-11.47-9.806-18.133-4.059L9.109 41.084A21.769 21.769 0 000 58.768c0 11.974 9.741 21.715 21.715 21.715 9.593 0 18.112-6.415 20.813-15.457h14.96c2.719 8.862 10.786 15.457 20.797 15.457 11.974 0 21.715-9.741 21.715-21.715 0-7.004-3.404-13.61-9.109-17.684zm-41.922-8.255h2.063c.058.325.559 2.254.559 2.254-.784-.14-2.396-.14-3.18 0-.001 0 .5-1.929.558-2.254zM50 38.705c3.5 0 5.655 2.061 6.922 4.06H43.078c1.266-1.999 3.421-4.06 6.922-4.06zM21.715 74.844c-8.865 0-16.076-7.212-16.076-16.076 0-8.865 7.211-16.076 16.076-16.076 8.864 0 16.076 7.211 16.076 16.076.001 8.864-7.211 16.076-16.076 16.076zm18.499-23.246c-2.874-7.411-10.468-13.153-19.655-12.631l14.979-12.32c2.455-2.127 5.528-1.651 7.427-.258 1.746 1.279 2.875 3.521 2.167 6.371l-1.078 4.125c-1.349.951-2.665 2.364-3.637 4.463l-.209 5.177h1.328l-1.322 5.073zm1.844 7.789l3.362-12.862h9.16l3.361 12.862H42.058zm16.407-12.863h1.327l-.21-5.177c-.971-2.099-2.287-3.512-3.637-4.463l-1.077-4.125c-.819-3.251.825-5.758 3.038-6.895 2.703-1.389 5.234-.362 6.555.781l14.98 12.32c-8.906-.664-16.775 5.215-19.652 12.622l-1.324-5.063zm19.82 28.32c-8.865 0-16.076-7.212-16.076-16.076 0-8.865 7.211-16.076 16.076-16.076 8.864 0 16.076 7.211 16.076 16.076 0 8.864-7.212 16.076-16.076 16.076zm0-6.392v3.76c-7.414 0-13.445-6.031-13.445-13.444h3.76c0 5.339 4.344 9.684 9.685 9.684zm-56.54 0v3.76c-7.414 0-13.445-6.031-13.445-13.444h3.759c.001 5.339 4.345 9.684 9.686 9.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});