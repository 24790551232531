import { symbol, dict, isDict, debugToString, EMPTY_ARRAY, isObject } from '@glimmer/util';
import { CONSTANT_TAG, validateTag, valueForTag, track, consumeTag, dirtyTag, createTag } from '@glimmer/validator';
import { DEBUG } from '@glimmer/env';
import { getProp, setProp, getPath, toIterator } from '@glimmer/global-context';

class CachedReference {
  constructor() {
    this.tag = null;
    this.lastRevision = null;
    this.lastValue = null;
  }

  value() {
    let {
      lastRevision,
      tag
    } = this;
    let lastValue;

    if (tag === null || !validateTag(tag, lastRevision)) {
      tag = this.tag = track(() => {
        lastValue = this.lastValue = this.compute();
      }, DEBUG && this.debugLabel);
      this.lastRevision = valueForTag(tag);
    } else {
      lastValue = this.lastValue;
    }

    consumeTag(tag);
    return lastValue;
  }

  isConst() {
    if (DEBUG && this.tag === null) {
      throw new Error('Attempted to read isConst before the reference was calculated for the first time');
    }

    return this.tag === CONSTANT_TAG;
  }

} //////////


class ReferenceCache {
  constructor(reference) {
    this.reference = reference;
    this.lastValue = reference.value();
  }

  revalidate() {
    let {
      lastValue
    } = this;
    let currentValue = this.reference.value();
    if (currentValue === lastValue) return NOT_MODIFIED;
    this.lastValue = currentValue;
    return currentValue;
  }

}

const NOT_MODIFIED = symbol('NOT_MODIFIED');

function isModified(value) {
  return value !== NOT_MODIFIED;
}

class PrimitiveReference {
  constructor(inner) {
    this.inner = inner;
  }

  value() {
    return this.inner;
  }

  isConst() {
    return true;
  }

  get(_key) {
    return UNDEFINED_REFERENCE;
  }

}

const UNDEFINED_REFERENCE = new PrimitiveReference(undefined);

class ConstReference {
  constructor(inner) {
    this.inner = inner;
  }

  value() {
    return this.inner;
  }

  isConst() {
    return true;
  }

  get(_key) {
    return UNDEFINED_REFERENCE;
  }

}

const UPDATE_REFERENCED_VALUE = symbol('UPDATE_REFERENCED_VALUE');
/**
 * RootReferences refer to a constant root value within a template. For
 * instance, the `this` in `{{this.some.prop}}`. This is typically a:
 *
 * - Component
 * - Controller
 * - Helper
 *
 * Or another "top level" template construct, if you will. PropertyReferences
 * chain off a root reference in the template, and can then be passed around and
 * used at will.
 */

class RootReference extends CachedReference {
  constructor() {
    super(...arguments);
    this.children = dict();
  }

  get(key) {
    // References should in general be identical to one another, so we can usually
    // deduplicate them in production. However, in DEBUG we need unique references
    // so we can properly key off them for the logging context.
    if (DEBUG) {
      return new PropertyReference(this, key);
    } else {
      let ref = this.children[key];

      if (ref === undefined) {
        ref = this.children[key] = new PropertyReference(this, key);
      }

      return ref;
    }
  }

}

class ComponentRootReference extends RootReference {
  constructor(inner) {
    super();
    this.inner = inner;

    if (DEBUG) {
      this.debugLabel = 'this';
    }
  }

  value() {
    return this.inner;
  }

  isConst() {
    return true;
  } // Make type checker happy...


  compute() {
    return this.inner;
  }

}

class HelperRootReference extends RootReference {
  constructor(fn, args, debugName) {
    super();

    if (DEBUG) {
      let name = debugName || fn.name;
      this.debugLabel = `(result of a \`${name}\` helper)`;
    }

    this.compute = fn.bind(null, args);
  }

}
/**
 * PropertyReferences represent a property that has been accessed on a root, or
 * another property (or iterable, see below). `some` and `prop` in
 * `{{this.some.prop}}` are each property references, `some` being a property of
 * `this`, and `prop` being a property of `some`. They are constructed by
 * recursively calling `get` on the previous reference as a template chain is
 * followed.
 */


class PropertyReference extends CachedReference {
  constructor(parentReference, propertyKey) {
    super();
    this.parentReference = parentReference;
    this.propertyKey = propertyKey;
    this.children = dict();

    if (DEBUG) {
      this.debugLabel = `${parentReference.debugLabel}.${propertyKey}`;
    }
  }

  compute() {
    let {
      parentReference,
      propertyKey
    } = this;
    let parentValue = parentReference.value();

    if (isDict(parentValue)) {
      return getProp(parentValue, propertyKey);
    }
  }

  get(key) {
    // References should in general be identical to one another, so we can usually
    // deduplicate them in production. However, in DEBUG we need unique references
    // so we can properly key off them for the logging context.
    if (DEBUG) {
      return new PropertyReference(this, key);
    } else {
      let ref = this.children[key];

      if (ref === undefined) {
        ref = this.children[key] = new PropertyReference(this, key);
      }

      return ref;
    }
  }

  [UPDATE_REFERENCED_VALUE](value) {
    let {
      parentReference,
      propertyKey
    } = this;
    let parentValue = parentReference.value();

    if (isDict(parentValue)) {
      setProp(parentValue, propertyKey, value);
    }
  }

} //////////

/**
 * IterationItemReferences represent an individual item in an iterable `each`.
 * They are similar to PropertyReferences, but since iteration items need to be
 * updated they have slightly different behavior. Concretely, they are the
 * `item` in:
 *
 * ```hbs
 * {{#each this.items as |item|}}
 *   {{item.foo}}
 * {{/each}}
 * ```
 *
 * Properties can chain off an iteration item, just like with the other template
 * reference types.
 */


class IterationItemReference {
  constructor(parentReference, itemValue, itemKey) {
    this.parentReference = parentReference;
    this.itemValue = itemValue;
    this.children = dict();
    this.tag = createTag();

    if (DEBUG) {
      this.debugLabel = `${parentReference.debugLabel}.${debugToString(itemKey)}`;
    }
  }

  isConst() {
    return false;
  }

  value() {
    consumeTag(this.tag);
    return this.itemValue;
  }

  update(value) {
    if (value !== this.itemValue) {
      dirtyTag(this.tag);
      this.itemValue = value;
    }
  }

  get(key) {
    // References should in general be identical to one another, so we can usually
    // deduplicate them in production. However, in DEBUG we need unique references
    // so we can properly key off them for the logging context.
    if (DEBUG) {
      return new PropertyReference(this, key);
    } else {
      let ref = this.children[key];

      if (ref === undefined) {
        ref = this.children[key] = new PropertyReference(this, key);
      }

      return ref;
    }
  }

}

const NULL_IDENTITY = {};

const KEY = (_, index) => index;

const INDEX = (_, index) => String(index);

const IDENTITY = item => {
  if (item === null) {
    // Returning null as an identity will cause failures since the iterator
    // can't tell that it's actually supposed to be null
    return NULL_IDENTITY;
  }

  return item;
};

function keyForPath(path) {
  if (DEBUG && path[0] === '@') {
    throw new Error(`invalid keypath: '${path}', valid keys: @index, @identity, or a path`);
  }

  return uniqueKeyFor(item => getPath(item, path));
}

function makeKeyFor(key) {
  switch (key) {
    case '@key':
      return uniqueKeyFor(KEY);

    case '@index':
      return uniqueKeyFor(INDEX);

    case '@identity':
      return uniqueKeyFor(IDENTITY);

    default:
      return keyForPath(key);
  }
}

class WeakMapWithPrimitives {
  get weakMap() {
    if (this._weakMap === undefined) {
      this._weakMap = new WeakMap();
    }

    return this._weakMap;
  }

  get primitiveMap() {
    if (this._primitiveMap === undefined) {
      this._primitiveMap = new Map();
    }

    return this._primitiveMap;
  }

  set(key, value) {
    if (isObject(key) || typeof key === 'function') {
      this.weakMap.set(key, value);
    } else {
      this.primitiveMap.set(key, value);
    }
  }

  get(key) {
    if (isObject(key) || typeof key === 'function') {
      return this.weakMap.get(key);
    } else {
      return this.primitiveMap.get(key);
    }
  }

}

const IDENTITIES = new WeakMapWithPrimitives();

function identityForNthOccurence(value, count) {
  let identities = IDENTITIES.get(value);

  if (identities === undefined) {
    identities = [];
    IDENTITIES.set(value, identities);
  }

  let identity = identities[count];

  if (identity === undefined) {
    identity = {
      value,
      count
    };
    identities[count] = identity;
  }

  return identity;
}
/**
 * When iterating over a list, it's possible that an item with the same unique
 * key could be encountered twice:
 *
 * ```js
 * let arr = ['same', 'different', 'same', 'same'];
 * ```
 *
 * In general, we want to treat these items as _unique within the list_. To do
 * this, we track the occurences of every item as we iterate the list, and when
 * an item occurs more than once, we generate a new unique key just for that
 * item, and that occurence within the list. The next time we iterate the list,
 * and encounter an item for the nth time, we can get the _same_ key, and let
 * Glimmer know that it should reuse the DOM for the previous nth occurence.
 */


function uniqueKeyFor(keyFor) {
  let seen = new WeakMapWithPrimitives();
  return (value, memo) => {
    let key = keyFor(value, memo);
    let count = seen.get(key) || 0;
    seen.set(key, count + 1);

    if (count === 0) {
      return key;
    }

    return identityForNthOccurence(key, count);
  };
}

class IterableReference extends CachedReference {
  constructor(parentRef, key) {
    super();
    this.parentRef = parentRef;
    this.key = key;
    this.iterator = null;
  }

  isConst() {
    return false;
  }

  isDone() {
    return this.iterator === null;
  }

  compute() {
    return !this.isEmpty();
  }

  isEmpty() {
    let iterator = this.iterator = this.createIterator();
    return iterator.isEmpty();
  }

  next() {
    let iterator = this.iterator;
    let item = iterator.next();

    if (item === null) {
      this.iterator = null;
    }

    return item;
  }

  createIterator() {
    let {
      parentRef,
      key
    } = this;
    let iterable = parentRef.value();
    let keyFor = makeKeyFor(key);

    if (Array.isArray(iterable)) {
      return new ArrayIterator(iterable, keyFor);
    }

    let maybeIterator = toIterator(iterable);

    if (maybeIterator === null) {
      return new ArrayIterator(EMPTY_ARRAY, () => null);
    }

    return new IteratorWrapper(maybeIterator, keyFor);
  }

  childRefFor(key, value) {
    let {
      parentRef
    } = this;
    return new IterationItemReference(parentRef, value, DEBUG ? `(key: ${debugToString(key)}` : '');
  }

}

class IteratorWrapper {
  constructor(inner, keyFor) {
    this.inner = inner;
    this.keyFor = keyFor;
  }

  isEmpty() {
    return this.inner.isEmpty();
  }

  next() {
    let nextValue = this.inner.next();

    if (nextValue !== null) {
      nextValue.key = this.keyFor(nextValue.value, nextValue.memo);
    }

    return nextValue;
  }

}

class ArrayIterator {
  constructor(iterator, keyFor) {
    this.iterator = iterator;
    this.keyFor = keyFor;
    this.pos = 0;

    if (iterator.length === 0) {
      this.current = {
        kind: 'empty'
      };
    } else {
      this.current = {
        kind: 'first',
        value: iterator[this.pos]
      };
    }
  }

  isEmpty() {
    return this.current.kind === 'empty';
  }

  next() {
    let value;
    let current = this.current;

    if (current.kind === 'first') {
      this.current = {
        kind: 'progress'
      };
      value = current.value;
    } else if (this.pos >= this.iterator.length - 1) {
      return null;
    } else {
      value = this.iterator[++this.pos];
    }

    let {
      keyFor
    } = this;
    let key = keyFor(value, this.pos);
    let memo = this.pos;
    return {
      key,
      value,
      memo
    };
  }

}

export { CachedReference, ReferenceCache, isModified, ConstReference, UNDEFINED_REFERENCE, IterableReference, UPDATE_REFERENCED_VALUE, RootReference, ComponentRootReference, HelperRootReference, PropertyReference, IterationItemReference };