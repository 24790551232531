define("ember-svg-jar/inlined/quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 57.5c-10.2 0-19-7-19-22.2C0 13.9 17.4 1.4 27.4.1l2 5.9c-5.8 3.8-10.5 10.2-10 14 6.5.5 17 3.9 17 18 .1 10.6-5.9 19.5-17.4 19.5zm43.5 0c-10.2 0-19-7-19-22.2C43.5 13.9 60.9 1.4 70.9.1l2 5.9C67.2 9.8 62.5 16.2 63 20c6.4.5 17 3.9 17 18 0 10.6-6.1 19.5-17.5 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 80 58"
    }
  };
  _exports.default = _default;
});