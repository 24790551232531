define("ember-svg-jar/inlined/comp-set-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<circle cx=\"14.0625\" cy=\"7.5\" r=\"7\" fill=\"white\" stroke=\"#BFE8EB\"/>\n<circle cx=\"7.5\" cy=\"7.5\" r=\"7.5\" fill=\"#A8A8A8\"/>\n<circle cx=\"14.0625\" cy=\"7.5\" r=\"7\" fill=\"#D8D8D8\" fill-opacity=\"0.5\" stroke=\"#E9E9E9\"/>\n",
    "attrs": {
      "viewBox": "0 0 22 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});