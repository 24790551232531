define("ember-svg-jar/inlined/triangle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 71.875c0-1.69.62-3.157 1.854-4.396l43.752-43.75c1.234-1.234 2.7-1.855 4.396-1.855 1.692 0 3.158.617 4.396 1.854l43.75 43.75c1.235 1.235 1.852 2.7 1.852 4.395 0 1.693-.62 3.158-1.855 4.395-1.235 1.238-2.7 1.855-4.395 1.855H6.25c-1.69 0-3.157-.62-4.396-1.855C.62 75.032 0 73.568 0 71.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});