define("ember-svg-jar/inlined/dynamic-markup-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M243 37.038h-15.623V27.55h-26.643v-7.856h-9.463v-3.615h-29.026v16.623H133.5v4.15h-20V26.26H79v12.253H47.482V26.26H3.208v12.265H0\" stroke=\"#9088F7\" stroke-width=\".79\"/><path d=\"M243 32.888h-14.266V15.543h-27.463V11.93h-26.026v16.623H136.5v12.45h-19V22.11H82v12.253H53.482V22.11H23.208v12.266H0\" stroke=\"#3D6EF6\" stroke-width=\".79\"/><path d=\"M243 39.529h-19.623V30.04h-22.643v.445h-29.463v4.686h-9.026v.022H133.5v4.15h-20V28.75H79v7.273H58l-1-2.076H31l-2 7.068H0\" stroke=\"#69C5CD\" stroke-width=\".79\"/><path d=\"M243 37.581h-19.623V8.278h-27.643V1h-24.463v18.253h-9.026v.05H140.5v5.4h-26V21.08H86v8.433H58l-1-4.775H31l-2 16.263H0\" stroke=\"#B966CF\" stroke-width=\".79\"/>",
    "attrs": {
      "viewBox": "0 0 243 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});