define("ember-ref-modifier/modifiers/ref", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function hasValidTarget(target) {
    return _typeof(target) === 'object' && target !== null && !Array.isArray(target);
  }

  function hasValidProperty(prop) {
    return typeof prop === 'string';
  }

  function getParams(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        target = _ref2[0],
        propName = _ref2[1];

    if (typeof target === 'function') {
      return {
        cb: target
      };
    }

    (false && !(typeof target !== 'string') && Ember.assert("String ".concat(target, " used as context for ref modifier. Should be {{ref context \"").concat(target, "\"}}. You passed {{ref \"").concat(target, "\" context}}"), typeof target !== 'string'));
    return {
      target: target,
      propName: propName
    };
  }

  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities ? Ember._modifierManagerCapabilities('3.13') : undefined,
      createModifier: function createModifier() {
        return {
          element: undefined,
          propName: undefined,
          cb: undefined,
          target: undefined
        };
      },
      installModifier: function installModifier(state, element, _ref3) {
        var positional = _ref3.positional;

        var _getParams = getParams(positional),
            propName = _getParams.propName,
            target = _getParams.target,
            cb = _getParams.cb;

        if (cb) {
          state.cb = cb;

          this._runInContext(cb, element);

          return;
        }

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          this._setInContext(target, propName, element);

          state.propName = propName;
          state.target = target;
        }

        state.element = element;
      },
      updateModifier: function updateModifier(state, _ref4) {
        var positional = _ref4.positional;

        var _getParams2 = getParams(positional),
            propName = _getParams2.propName,
            target = _getParams2.target,
            cb = _getParams2.cb;

        if (cb) {
          state.cb = cb;

          this._runInContext(cb, state.element);

          return;
        }

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          if (hasValidProperty(state.propName) && hasValidTarget(state.target)) {
            if (Ember.get(target, propName) !== Ember.get(state.target, state.propName)) {
              this._setInContext(state.target, state.propName, null);
            }
          }

          this._setInContext(target, propName, state.element);

          state.propName = propName;
          state.target = target;
        }
      },
      _setInContext: function _setInContext(target, propName, value) {
        Ember.run.next(this, '_setValues', target, propName, value);
      },
      _runInContext: function _runInContext(cb, value) {
        Ember.run.next(this, '_runCb', cb, value);
      },
      _runCb: function _runCb(cb, value) {
        cb(value);
      },
      _setValues: function _setValues(target, propName, value) {
        if (target.isDestroyed || target.isDestroying) {
          return;
        }

        Ember.set(target, propName, value);
      },
      destroyModifier: function destroyModifier(_ref5) {
        var target = _ref5.target,
            propName = _ref5.propName,
            cb = _ref5.cb;

        if (cb) {
          return;
        }

        if (hasValidProperty(propName) && hasValidTarget(target)) {
          this._setInContext(target, propName, null);
        }
      }
    };
  }, function RefModifier() {
    _classCallCheck(this, RefModifier);
  });

  _exports.default = _default;
});