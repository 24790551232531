define("ember-svg-jar/inlined/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 4c6.617 0 12 5.383 12 12s-5.383 12-12 12S4 22.617 4 16 9.383 4 16 4m0-4C7.164 0 0 7.164 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0z\"/><path d=\"M21.422 18.578L18 15.152V8h-4.023v7.992c0 .602.277 1.121.695 1.492l3.922 3.922 2.828-2.828z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});