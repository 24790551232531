define("ember-svg-jar/inlined/cloud-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#010101\" d=\"M64.062 67.97c-1.383 0-2.628.6-3.485 1.55l-5.89 6.546V52.344a4.689 4.689 0 00-9.378 0v23.722l-5.89-6.545a4.684 4.684 0 00-3.484-1.55 4.686 4.686 0 00-3.486 7.822l14.063 15.625.012-.01c.858.95 2.09 1.56 3.472 1.56 1.383 0 2.614-.61 3.473-1.563l.013.01L67.548 75.79a4.665 4.665 0 001.18-2.686v-.01c.015-.143.022-.29.022-.437v-.002a4.69 4.69 0 00-4.688-4.686zm19.355-38.04C78.2 16.542 65.24 7.032 50 7.032c-15.237 0-28.2 9.508-33.417 22.897C6.985 32.862 0 41.785 0 52.343c0 12.944 10.494 23.438 23.438 23.438 2.59 0 4.688-2.098 4.688-4.688s-2.098-4.688-4.688-4.688c-7.766 0-14.062-6.296-14.062-14.062 0-4.188 1.84-7.938 4.746-10.513.3-.264.612-.516.932-.756.02-.016.043-.03.062-.047.304-.225.617-.434.94-.634l.15-.09c.3-.182.604-.352.917-.51.086-.043.173-.084.26-.125.29-.14.58-.27.877-.388.13-.052.263-.097.394-.144.267-.097.536-.192.81-.273.183-.054.37-.097.554-.142.237-.06.474-.123.714-.17.253-.05.513-.083.77-.117.187-.025.37-.06.557-.08.452-.045.912-.072 1.377-.072.146 0 .29.018.434.023.144-.802.323-1.59.536-2.363.05-.19.122-.37.178-.558.176-.586.355-1.17.57-1.737.063-.172.143-.336.21-.506.23-.58.468-1.152.738-1.71.066-.134.14-.262.208-.395.294-.58.6-1.157.935-1.713C31.885 21.59 40.325 16.406 50 16.406c9.674 0 18.118 5.184 22.754 12.916.333.558.64 1.133.936 1.713.067.133.142.26.207.396.27.557.51 1.132.74 1.71.067.17.146.333.21.505.214.567.395 1.15.568 1.737.057.188.128.37.18.558.21.774.39 1.56.534 2.363.143-.005.286-.022.433-.022.466 0 .925.026 1.378.07.19.02.372.054.558.08.257.036.516.067.77.117.244.047.48.11.714.17.187.046.372.09.554.143.274.08.545.176.81.273.132.05.267.092.395.144.3.118.59.25.878.387l.262.125c.314.157.62.327.92.508l.15.09c.32.2.634.412.938.635.02.016.042.03.063.047.322.24.636.492.933.758 2.904 2.58 4.744 6.328 4.744 10.516 0 7.767-6.296 14.062-14.062 14.062-2.59 0-4.688 2.098-4.688 4.688s2.098 4.688 4.688 4.688C89.507 75.782 100 65.288 100 52.344c0-10.558-6.985-19.48-16.583-22.412z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});