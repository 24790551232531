define("ember-svg-jar/inlined/arrow-round-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 16c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8zm-1.4-5c-.1-.1-.1-.2-.2-.3 0-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3 0-.1.1-.2.2-.3L8.5 8 6.6 6.1c0-.1-.1-.2-.1-.3 0-.1-.1-.2-.1-.3s0-.2.1-.3c0-.1.1-.2.1-.2.3-.3.8-.3 1.1 0l2.5 2.5c.3.3.3.8 0 1.1L7.8 11c-.3.3-.9.3-1.2 0z\" fill=\"#69c5cd\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});