define("ember-svg-jar/inlined/relay/reservations-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st3{fill:none;stroke-linecap:round}</style><path d=\"M4.2 7.6l2.1 2.2 4.2-4.4\" fill=\"none\" stroke-linecap=\"square\"/><path d=\"M2.1 2.2H1v10.7h12V2.2h-1.1\" fill=\"none\" stroke-linejoin=\"round\"/><path fill=\"none\" d=\"M5.4 2h3.2\"/><path class=\"st3\" d=\"M3.7 2.7V1.1M10.3 2.7V1.1\"/>",
    "attrs": {
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});