define("ember-svg-jar/inlined/arrow-down-long", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M16.7,31.7l7-7c0.2-0.2,0.3-0.4,0.3-0.7c0-0.1,0-0.3-0.1-0.4C23.8,23.2,23.4,23,23,23h-5V1c0-0.6-0.4-1-1-1h-2\n\tc-0.6,0-1,0.4-1,1v22H9c-0.4,0-0.8,0.2-0.9,0.6C8,23.7,8,23.8,8,24c0,0.3,0.1,0.5,0.3,0.7l7,7C15.7,32,16.3,32,16.7,31.7z\"/>\n",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});