define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M65 31h-9v-7C56 9.514 49.326 1 36 1 22.673 1 16 9.514 16 24v7H6c-2.762 0-5 3.239-5 6v39c0 2.75 2.142 5.691 4.759 6.535 0 0 12.991 4.465 15.741 4.465h29c2.75 0 15.741-4.465 15.741-4.465C68.857 81.691 71 78.75 71 76V37c0-2.761-3.238-6-6-6zM42.188 70.818h-12l2.031-11.308c-1.751-1.165-2.906-3.626-2.906-5.886a6.5 6.5 0 1113 0c0 1.963-.875 4.191-2.252 5.383l2.127 11.811zM46 31H26v-9c0-7.225 3.986-11 10-11 6.014 0 10 3.775 10 11v9z\"/>",
    "attrs": {
      "version": "1",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "72",
      "height": "88",
      "viewBox": "0 0 72 88"
    }
  };
  _exports.default = _default;
});