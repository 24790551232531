define("ember-svg-jar/inlined/arrow-left-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 5l5 5 1.4-1.5L2.8 5l3.6-3.6L5 0 0 5z\"/>",
    "attrs": {
      "viewBox": "0 0 6.4 10",
      "width": "6.4",
      "height": "10"
    }
  };
  _exports.default = _default;
});