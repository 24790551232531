define("ember-svg-jar/inlined/download-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#43B6C0\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M9 6.5V1h2v5.5a.5.5 0 01-.5.5H14l-4 5-4-5h3.5a.5.5 0 01-.5-.5z\" fill=\"#43B6C0\" stroke-linejoin=\"round\"/><path d=\"M18 11v6c0 .276-.239.5-.533.5H2.533C2.24 17.5 2 17.276 2 17v-6\" stroke-width=\"2.5\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 19",
      "width": "20",
      "height": "19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});