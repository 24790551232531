define("ember-svg-jar/inlined/triangle-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#020202\" d=\"M28.125 0c1.692 0 3.157.619 4.396 1.854l43.75 43.751c1.234 1.235 1.854 2.7 1.854 4.396 0 1.693-.617 3.158-1.854 4.395l-43.752 43.75C31.285 99.383 29.82 100 28.125 100c-1.693 0-3.158-.619-4.395-1.855-1.238-1.236-1.855-2.701-1.855-4.395V6.25c0-1.692.619-3.157 1.855-4.396C24.968.619 26.431 0 28.125 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});