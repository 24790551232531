define("ember-svg-jar/inlined/signpost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.555 55.383c.377.394.9.617 1.445.617h23v35H31a2 2 0 000 4h34a2 2 0 000-4h-9V41h26a2 2 0 001.445-.617l11-11.5a2 2 0 000-2.766l-11-11.5A2.002 2.002 0 0082 14H56V7a2 2 0 00-2-2H44a2 2 0 00-2 2v22H19a2 2 0 00-1.445.617l-11 11.5a2 2 0 000 2.766l11 11.5zM81.145 18l9.088 9.5-9.087 9.5H56V18h25.146zM46 9h6v82h-6V9zM19.854 33H42v19H19.854l-9.087-9.5 9.087-9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});