define("ember-svg-jar/inlined/spin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.8 37.7c-1.1 11.2 4.5 21.4 15 27.2l-4.3 7.9C6.8 65.2-.6 51.8.9 36.8c1.5-14.6 11.3-27 25.2-32-.2-1-.6-3.1-.6-3.1l14.4 5-11.6 9.9s-.4-2-.5-2.8c-10 4.1-16.9 13.2-18 23.9zM59.2 7.6l-4.3 7.9c10.5 5.8 16 15.9 14.9 27.1-1.1 10.7-8 19.8-18 24-.2-.9-.5-2.8-.5-2.8l-11.6 9.9 14.3 5s-.4-2.1-.6-3.2c14-5 23.8-17.5 25.3-32.1 1.5-14.8-5.8-28.3-19.5-35.8z\" fill=\"#4b89c8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 80 80"
    }
  };
  _exports.default = _default;
});