define("ember-svg-jar/inlined/relay/merged-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 .5l2.5 3h-5z\"/><path d=\"M12.1 14C12.1 8.5 7 10.3 7 3c0 7.3-5.1 5.5-5.1 11\" fill=\"none\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});