define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M78.298 71.532c-13.111-4.777-17.303-8.809-17.303-17.441 0-5.181 4.296-8.539 5.76-12.979 1.465-4.44 2.312-9.697 3.017-13.521s.984-5.303 1.367-9.377C71.606 13.128 68.202 0 50 0 31.803 0 28.388 13.128 28.867 18.213c.383 4.075.664 5.554 1.367 9.377.703 3.824 1.542 9.081 3.005 13.521 1.464 4.44 5.766 7.798 5.766 12.979 0 8.633-4.191 12.664-17.303 17.441C8.542 76.319 0 81.04 0 84.375V100h100V84.375c0-3.33-8.548-8.051-21.702-12.843z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});