define("ember-google-maps/templates/components/g-map/overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "75hBbt6f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,1,[34,0]],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"_contentId\",\"_targetPane\",\"in-element\",\"if\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/overlay.hbs"
    }
  });

  _exports.default = _default;
});