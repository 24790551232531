define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M100 93.9L70.5 64.4c5.4-6.8 8.7-15.4 8.7-24.8C79.2 17.7 61.4 0 39.6 0 17.7 0 0 17.8 0 39.6s17.7 39.6 39.6 39.6c9.4 0 18.1-3.3 24.9-8.8L93.9 100l6.1-6.1zM17.7 61.6C11.8 55.7 8.6 48 8.6 39.7s3.2-16.1 9.1-21.9c5.8-5.9 13.7-9.1 21.9-9.1s16.1 3.2 21.9 9.1c5.9 5.9 9.1 13.7 9.1 21.9s-3.2 16.1-9.1 21.9c-5.9 5.9-13.7 9.1-21.9 9.1-8.3 0-16.1-3.2-21.9-9.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});