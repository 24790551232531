define("ember-svg-jar/inlined/arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.1 9.9L16 23 28.9 9.9 30 11 16 25 2 11l1.1-1.1z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});