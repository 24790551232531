define("ember-svg-jar/inlined/statements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M42.982 46.9c.005-.06.035-.118.035-.182V22.11c0-.055-.023-.1-.03-.157a.985.985 0 00-.053-.25 1.047 1.047 0 00-.11-.24 1.012 1.012 0 00-.128-.198 1.475 1.475 0 00-.21-.193c-.036-.03-.06-.07-.105-.1l-5.305-3.256V3.644a3.554 3.554 0 00-3.548-3.55H9.707a3.555 3.555 0 00-3.55 3.55v14.08L.852 20.97c-.04.03-.067.07-.105.1a1.066 1.066 0 00-.204.193.966.966 0 00-.14.203c-.04.07-.076.146-.106.228a1.278 1.278 0 00-.052.274c-.006.047-.03.087-.03.14v24.608c0 .058.027.106.033.164.014.1.026.198.06.297.03.075.074.14.115.21.038.064.056.128.102.186.015.017.037.03.053.04.052.058.113.094.174.14.082.064.158.13.25.17.045.023.098.03.147.04.116.04.235.07.36.076.013 0 .024.007.036.007h40.13s.004.004.01.004c.088 0 .176-.04.262-.058.088-.017.182-.023.263-.06.06-.022.102-.07.154-.098.11-.064.21-.134.298-.227.012-.01.035-.017.046-.035.04-.053.06-.11.087-.17.053-.075.1-.146.134-.233.027-.092.04-.18.052-.272zM2.88 25.467l11.844 8.472L2.88 43.864V25.467zm18.736 6.173l16.4 13.75h-32.8l16.4-13.75zm6.892 2.3l11.842-8.47v18.393L28.508 33.94zm5.02-31.386c.6 0 1.09.487 1.09 1.09V26.29l-8.223 5.88-3.132-2.63c1.167-.39 1.862-1.22 1.862-2.527 0-1.482-.84-2.112-2.312-2.766l-1.308-.577c-.84-.38-1.056-.596-1.056-1.045 0-.628.513-.818 1.458-.818.467 0 .904.012 1.345.053l.128.7h1.42V20.4c-.35-.086-1.307-.238-2.24-.29v-1.32h-1.85v1.384c-1.71.227-2.603 1.033-2.603 2.486 0 1.534.907 2.065 2.21 2.678l1.384.653c.817.392 1.068.602 1.068 1.098 0 .666-.49.98-1.483.98-.54 0-.957-.012-1.38-.03l-.13-.752h-1.42v2.252c.362.065.864.13 1.395.182l-2.92 2.45-8.22-5.88V3.643c0-.603.492-1.09 1.093-1.09h23.82z\"/><path d=\"M12.147 6.297h18.94V8.24h-18.94zm0 3.483h18.94v1.944h-18.94zm0 3.275h18.94v1.943h-18.94z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "43",
      "height": "48",
      "viewBox": "0 0 43 48"
    }
  };
  _exports.default = _default;
});