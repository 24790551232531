define("ember-svg-jar/inlined/bp-guidance-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.2 13.55c.233-.317.367-.7.367-1.1 0-.6-.317-1.15-.867-1.45h-.017c-.233-.117-.5-.2-.766-.2h-1.084V.917c0-.35-.266-.684-.616-.7a.645.645 0 0 0-.684.633v8.033H10.45c-.317 0-.617.117-.833.317l-2.034 2.433s-.366.467-.616.467h-.684a.34.34 0 0 0-.333-.233H3.033a.37.37 0 0 0-.366.366v5.784c0 .2.166.366.366.366H5.95c.183 0 .317-.116.35-.3h.283c.25.034.634.067 1.017.317.567.2 1.8 1.233 2.4 1.233h4.85v-.016c.833 0 2-.4 1.517-1.517v-.05l.116-.1c.434-.317.7-.833.717-1.35a1.616 1.616 0 0 0-.117-.733l-.016-.067a1.7 1.7 0 0 0 .516-1.217 1.746 1.746 0 0 0-.383-1.033zm-.833.283l.183.2c.117.15.217.334.217.567a.888.888 0 0 1-.317.683l-.117.084a.407.407 0 0 0-.116.45l.116.316c.05.117.067.267.067.417-.017.3-.167.583-.4.767l-.283.233c-.1.067-.2.417-.05.633.25.417-.267.634-.834.667L10 18.8c-.433 0-2.2-1.383-2.4-1.383H6.317v-4.684h1.266c.184 0 2.567-2.95 2.567-2.95a.526.526 0 0 1 .317-.116h3.066V10.8l-1.033.017c-.017.116-.033.533.033.616.067.084.184.15.3.15H15.933c.117 0 .284.034.4.1h.017c.283.15.45.434.45.75 0 .284-.117.567-.317.784l-.066.066c-.184.15-.2.4-.05.55z\" fill=\"#E64689\"/><path d=\"M6.683 4.283l5 2.884a.62.62 0 0 0 .934-.534V.85a.62.62 0 0 0-.934-.533l-5 2.883a.626.626 0 0 0 0 1.083z\" fill=\"#E64689\"/><path d=\"M16.367 13.833l.183.2c.117.15.217.334.217.567a.888.888 0 0 1-.317.683l-.117.084a.407.407 0 0 0-.116.45l.116.316c.05.117.067.267.067.417-.017.3-.167.583-.4.767l-.283.233c-.1.067-.2.417-.05.633.25.417-.267.634-.834.667L10 18.8c-.433 0-2.2-1.383-2.4-1.383H6.317v-4.684h1.266c.184 0 2.567-2.95 2.567-2.95a.526.526 0 0 1 .317-.116h3.066V10.8l-1.033.017c-.017.116-.033.533.033.616.067.084.184.15.3.15H15.933c.117 0 .284.034.4.1h.017c.283.15.45.434.45.75 0 .284-.117.567-.317.784l-.066.066c-.184.15-.2.4-.05.55z\" fill=\"#FBDEEB\"/>",
    "attrs": {
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});