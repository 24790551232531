define("ember-svg-jar/inlined/pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.581 2.844L.244 9.18A.738.738 0 000 9.75v2.438c0 .487.325.812.813.812H3.25a.738.738 0 00.569-.244l6.337-6.337-3.575-3.575zm6.175-.163L10.32.244a.785.785 0 00-1.138 0L7.72 1.706l3.575 3.575 1.462-1.462a.785.785 0 000-1.138z\"/>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});