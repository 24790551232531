define("ember-svg-jar/inlined/onboarding/listing-placeholder-part-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M50 65H2c-1.1 0-2-.9-2-2V33c0-1.1.9-2 2-2h48c1.1 0 2 .9 2 2v30c0 1.1-.9 2-2 2zm2 30V77c0-1.1-.9-2-2-2H2c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h48c1.1 0 2-.9 2-2z\"/>",
    "attrs": {
      "viewBox": "0 0 52 128"
    }
  };
  _exports.default = _default;
});