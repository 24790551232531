define("ember-svg-jar/inlined/people-social/linkedin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <path d=\"M18.3,2.2H3.7c-0.8,0-1.5,0.7-1.5,1.5v14.7c0,0.8,0.7,1.5,1.5,1.5h14.7c0.8,0,1.5-0.7,1.5-1.5V3.7\n    C19.8,2.9,19.1,2.2,18.3,2.2z M7.3,17.2H4.8V8.8h2.6V17.2z M6.1,7.7C5.1,7.7,4.4,7,4.4,6.1c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6\n    C7.7,7,7,7.7,6.1,7.7z M17.2,17.3h-2.6v-4.1c0-1.4-0.3-2.2-1.2-2.2c-1.2,0-1.7,0.9-1.7,2.2v4.1H9.2V8.9h2.4v1.2\n    c0.3-0.7,1.2-1.3,2.5-1.3c2.5,0,3.1,1.4,3.1,3.9V17.3z\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 22",
      "enable-background": "new 0 0 22 22"
    }
  };
  _exports.default = _default;
});