define("ember-svg-jar/inlined/icon-bathroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M0.956055 11.7012V4.16499C0.956055 2.55077 2.26464 1.24219 3.87886 1.24219H7.13256C8.74678 1.24219 10.0554 2.55077 10.0554 4.16499V4.67225\" stroke=\"#D8D8D8\" stroke-width=\"1.9\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/>\n<ellipse cx=\"10.6589\" cy=\"5.83341\" rx=\"5.22949\" ry=\"2.44043\" transform=\"rotate(-8 10.6589 5.83341)\" fill=\"#D8D8D8\"/>\n<path d=\"M7.5138 11.0405L7.54777 11.2822\" stroke=\"#D8D8D8\" stroke-width=\"2.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M11.38 10.4976L11.414 10.7392\" stroke=\"#D8D8D8\" stroke-width=\"2.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M15.2472 9.95361L15.2812 10.1953\" stroke=\"#D8D8D8\" stroke-width=\"2.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n",
    "attrs": {
      "width": "19",
      "height": "13",
      "viewBox": "0 0 19 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});