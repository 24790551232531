define("ember-mapbox-gl/templates/components/mapbox-gl-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mXiNeOpy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"popup\"],[[30,[36,0],[\"mapbox-gl-popup\"],[[\"marker\",\"MapboxGl\"],[[32,0,[\"marker\"]],[32,0,[\"MapboxGl\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"marker\",\"if\"]}",
    "meta": {
      "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl-marker.hbs"
    }
  });

  _exports.default = _default;
});