define("ember-svg-jar/inlined/icon-dynamic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M0.51462 8.23383C0.51462 7.94961 0.745023 7.71921 1.02924 7.71921C1.31346 7.71921 1.54386 7.94961 1.54386 8.23383V14.9239H0.51462V8.23383Z\" stroke=\"#FA8829\" stroke-width=\"1.02924\"/>\n<path d=\"M4.63156 5.14618C4.63156 4.86197 4.86197 4.63156 5.14618 4.63156C5.4304 4.63156 5.6608 4.86197 5.6608 5.14618V14.924H4.63156V5.14618Z\" stroke=\"#FA8829\" stroke-width=\"1.02924\"/>\n<path d=\"M8.74851 1.02924C8.74851 0.745023 8.97891 0.51462 9.26313 0.51462C9.54734 0.51462 9.77775 0.745023 9.77775 1.02924V14.924H8.74851V1.02924Z\" stroke=\"#FA8829\" stroke-width=\"1.02924\"/>\n<path d=\"M12.8654 9.26313C12.8654 8.97891 13.0959 8.74851 13.3801 8.74851C13.6643 8.74851 13.8947 8.97891 13.8947 9.26313V14.9239H12.8654V9.26313Z\" stroke=\"#FA8829\" stroke-width=\"1.02924\"/>\n<path d=\"M16.9825 6.17548C16.9825 5.89126 17.2129 5.66086 17.4971 5.66086C17.7814 5.66086 18.0118 5.89126 18.0118 6.17548V14.924H16.9825V6.17548Z\" stroke=\"#FA8829\" stroke-width=\"1.02924\"/>\n",
    "attrs": {
      "width": "19",
      "height": "16",
      "viewBox": "0 0 19 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});