define("ember-svg-jar/inlined/hotel-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style/><path d=\"M7.5 3a.5.5 0 00-.5.5V5H5.5a.5.5 0 00-.5.5V28H3.5a.5.5 0 000 1h25a.5.5 0 000-1H23V10h1v5.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5V7h-1V5.5a.5.5 0 00-.5-.5H21V3.5a.5.5 0 00-.5-.5h-13zM8 4h12v1H8V4zM6 6h16v22h-4v-5h.5a.5.5 0 000-1h-8a.5.5 0 000 1h.5v5H6V6zm1.5 1a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zm5 0a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zm5 0a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zM25 7h3v8h-3V7zM8 8h2v2H8V8zm5 0h2v2h-2V8zm5 0h2v2h-2V8zm5 0h1v1h-1V8zm3.5 0a.5.5 0 00-.5.5v5a.5.5 0 001 0v-5a.5.5 0 00-.5-.5zm-19 4a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zm5 0a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zm5 0a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zM8 13h2v2H8v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2zM7.5 17a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zm5 0a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zm5 0a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zM8 18h2v2H8v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2zm-6 5h2v5h-2v-5zm3 0h2v5h-2v-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});