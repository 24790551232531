define("ember-svg-jar/inlined/expert-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M95.7 67.9c-1.9-.1-5.8 2-6.9 2.5-3.8 1.9-9.1 4.9-12.6 7.3-.2.2-.6.4-.8.5-1.6 1-3.2 1.7-5 2.1-10.1 2.2-24.8 2.4-24.8 2.4h-.9c-.7 0-1.2-.4-1.3-1-.1-.7.4-1.3 1.1-1.4l21.9-2.1c2.4-.3 4.3-2.5 4.1-5.1-.3-2.5-2.6-4.4-5-4.1L46 68.8c-1.3 0-3.4-.3-4.7-.7-12.5-3.7-19.6-.2-23.4 1.4-.8-1.4-2.4-2.3-4.2-2.2l-8.4.6c-2.8.2-4.9 2.5-4.7 5.2L2 91.7c.2 2.7 2.6 4.7 5.4 4.5l8.4-.6c1.8-.1 3.2-1.2 3.8-2.7l36.9 1.6c4.1.4 8-.1 11.6-2l1.1-.6L97 75.8c1.4-.8 2.3-2.2 2.4-3.7 0-1.9-1-4-3.7-4.2zM10.4 91.8c-1.6 0-2.9-1.2-2.9-2.8 0-1.5 1.3-2.8 2.9-2.8s2.9 1.2 2.9 2.8c0 1.6-1.3 2.8-2.9 2.8zm64.2-21c2.9-1.6 8.7-4.4 11.8-5.6 1.6-.6 3.1-.6 4.6.4-1.1.4-2.2.9-3.2 1.4-3.3 1.7-9.1 4.8-12 6.8l-.9.6v-1.6c-.1-.7-.2-1.3-.4-1.9l.1-.1zm8.8-7.5c-2.3 1-6.6 2.5-8.7 3.7l-1.4.8c-.1.1-.3.2-.4.3-.6-.7-1.4-1.4-2.2-1.9 1.9-1 6.2-2.4 8.2-3.2 1.5-.7 3.1-.7 4.5.3zm-53-46.4l3.1 3.1c-1.7 2.5-2.9 5.4-3.5 8.5h-4.4c-1.1 0-1.9.9-1.9 1.9v5.3c0 1.1.9 1.9 1.9 1.9H30c.6 3.1 1.8 5.9 3.5 8.5l-3.1 3.1c-.8.8-.8 2 0 2.7l3.7 3.7c.8.8 2 .8 2.7 0l3.1-3.1c2.5 1.7 5.4 2.9 8.5 3.5v4.4c0 1.1.9 1.9 1.9 1.9h5.3c1.1 0 1.9-.9 1.9-1.9V56c3.1-.6 5.9-1.8 8.5-3.5l3.1 3.1c.8.8 2 .8 2.7 0l3.7-3.7c.8-.8.8-2 0-2.7l-3.1-3.1c1.7-2.5 2.9-5.4 3.5-8.5h4.4c1.1 0 1.9-.9 1.9-1.9v-5.3c0-1.1-.9-1.9-1.9-1.9H76c-.6-3.1-1.8-5.9-3.5-8.5l3.1-3.1c.8-.8.8-2 0-2.7l-3.7-3.7c-.8-.8-2-.8-2.7 0L66 13.6c-2.5-1.7-5.4-2.9-8.5-3.5V5.7c0-1.1-.9-1.9-1.9-1.9h-5.3c-1.1 0-1.9.9-1.9 1.9V10c-3.1.6-5.9 1.8-8.5 3.5l-3.1-3.1c-.8-.8-2-.8-2.7 0l-3.7 3.7c-.7.8-.7 2.1 0 2.8zm22.6.8c8.5 0 15.4 6.9 15.4 15.4S61.5 48.5 53 48.5s-15.4-6.9-15.4-15.4c.1-8.6 6.9-15.4 15.4-15.4zm-6.3 19.6l-3.6-3.7 3.8-3.8 3.6 3.7 8.7-8.6 3.8 3.8-8.7 8.6-3.8 3.8-3.8-3.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});