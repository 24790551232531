define("ember-svg-jar/inlined/dynamic-pricing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M92.5 3c-1-1-2.3-1.5-3.6-1.5H50.5c-.7 0-1.4.1-2 .4-.7.3-1.3.7-1.9 1.2L2.5 47.2c-2.1 2.1-2.1 5.4 0 7.3l38.4 38.4c2.1 2.1 5.4 2.1 7.3 0l44.3-44.3.1-.1c.9-1 1.4-2.2 1.4-3.6V6.6c0-1.4-.5-2.7-1.5-3.6zM77.8 8.8c2.4-2.4 6.4-2.4 8.8 0 2.4 2.4 2.4 6.4 0 8.8-2.4 2.4-6.4 2.4-8.8 0-2.4-2.4-2.4-6.4 0-8.8zM50.5 59.9l-17 4.5c-1.6.4-3-1-2.5-2.5l4.5-17c.4-1.6 2.3-2.1 3.5-1l12.5 12.5c1.1 1.2.5 3.1-1 3.5zM89 42.8c0 .6-.3 1.1-.7 1.5v.1L69.1 63.5 31.9 26.3 51 7.2c.2-.2.5-.3.8-.5.3-.1.5-.2.8-.2h20c-3.2 4.6-2.7 10.9 1.4 15s10.4 4.5 15 1.3v20z\"/><path d=\"M67.6 25.4l-17 4.5c-1.6.4-2.1 2.3-1 3.5L62 45.8c1.2 1.2 3.1.6 3.5-1l4.6-16.9c.4-1.6-1-3-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 96 96"
    }
  };
  _exports.default = _default;
});