define("ember-svg-jar/inlined/eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M26 15c-1.484-2.297-3.516-4.266-5.953-5.516A6.945 6.945 0 0121 13c0 3.86-3.14 7-7 7s-7-3.14-7-7c0-1.234.328-2.453.953-3.516C5.516 10.734 3.484 12.704 2 15c2.672 4.125 6.984 7 12 7s9.328-2.875 12-7zM14.75 9a.76.76 0 00-.75-.75c-2.61 0-4.75 2.14-4.75 4.75 0 .406.344.75.75.75s.75-.344.75-.75A3.268 3.268 0 0114 9.75a.76.76 0 00.75-.75zM28 15c0 .39-.125.75-.313 1.078C24.812 20.813 19.547 24 14 24S3.187 20.797.313 16.078C.125 15.75 0 15.391 0 15s.125-.75.313-1.078C3.188 9.203 8.453 6 14 6s10.813 3.203 13.687 7.922c.188.328.313.687.313 1.078z\" fill=\"#444\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});