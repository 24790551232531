define("ember-svg-jar/inlined/trash-bulk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M74.8 17.8V10c0-5.5-4.5-10-10-10H35.2c-5.5 0-10 4.5-10 10v7.8H3v9.9h10.6L16.8 84c.4 9 7.8 16 16.8 16h32.8c9 0 16.4-7 16.9-15.9l3.2-56.3H97v-9.9H74.8zM35.1 9.9h29.7v7.9H35.1V9.9zm38.3 73.8c-.2 3.6-3.2 6.4-6.7 6.4H33.4c-3.6 0-6.5-2.8-6.7-6.4l-3.2-56h53.1l-3.2 56z\"/><path d=\"M26.6 83.7c.2 3.6 3.2 6.4 6.7 6.4h33.2c3.6 0 6.5-2.8 6.7-6.4l3.2-56h-53l3.2 56z\" opacity=\".15\"/>",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});