define("ember-svg-jar/inlined/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"st0\" d=\"M6.2 5.2c-.3-.4-.1-1 .3-1.3.3-.2.6-.4 1-.5 1-.5 2-.7 3.1-.8 4.6-.3 8.6 3.1 8.9 7.7v.7l2.4.4-4.2 3.8-2.7-5 2.5.4v-.2c-.2-3.5-3.3-6.1-6.8-5.9-.8 0-1.6.2-2.4.6-.2.1-.5.3-.7.4-.5.3-1.1.2-1.4-.3zm-3.7 5.9v.7c.3 4.6 4.3 8 8.9 7.7 1.1-.1 2.1-.3 3.1-.8.3-.2.7-.3 1-.5.5-.3.6-.9.3-1.4-.3-.5-.9-.6-1.4-.3-.2.2-.5.3-.7.4-.7.4-1.5.6-2.4.6-3.5.2-6.6-2.4-6.8-5.9v-.2l2.5.5-2.7-5-4.2 3.8 2.4.4z\"/>",
    "attrs": {
      "viewBox": "0 0 22 22"
    }
  };
  _exports.default = _default;
});