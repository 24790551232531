define("ember-svg-jar/inlined/market-insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 36h4c1.1 0 2 .9 2 2v18h-4c-2.2 0-4-1.8-4-4V38c0-1.1.9-2 2-2zm8-6v26h8V30c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2zm10-2v28h8V28c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2zm10-12v40h8V16c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2zm10 16v24h4c2.2 0 4-1.8 4-4V32c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2z\" fill=\"#fff\"/><path d=\"M12 8h4v24c0 1.1-.9 2-2 2h-4c-1.1 0-2-.9-2-2V12c0-2.2 1.8-4 4-4zm6 16c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V8h-8v16zm10-2c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V8h-8v14zm10-12c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V8h-8v2zm10 16c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2V12c0-2.2-1.8-4-4-4h-4v18z\" fill=\"#8fd4da\"/>",
    "attrs": {
      "viewBox": "0 0 64 64"
    }
  };
  _exports.default = _default;
});