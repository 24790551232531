define("ember-svg-jar/inlined/github", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#BCBCBC\" d=\"M64.507 95.749c-2.438.474-3.303-1.026-3.303-2.308 0-1.584.053-6.763.053-13.196 0-4.486-1.534-7.412-3.259-8.905 10.707-1.189 21.958-5.257 21.958-23.728 0-5.25-1.867-9.54-4.949-12.907.499-1.211 2.146-6.102-.473-12.727 0 0-4.034-1.292-13.211 4.932-3.844-1.065-7.96-1.602-12.047-1.619-4.088.018-8.201.554-12.036 1.619-9.189-6.223-13.228-4.932-13.228-4.932-2.611 6.625-.967 11.516-.466 12.727-3.079 3.368-4.957 7.657-4.957 12.907 0 18.429 11.229 22.551 21.91 23.768-1.374 1.199-2.62 3.32-3.054 6.428-2.745 1.23-9.705 3.356-13.993-3.996 0 0-2.543-4.614-7.373-4.956 0 0-4.689-.061-.329 2.923 0 0 3.152 1.479 5.339 7.036 0 0 2.824 8.585 16.202 5.676.021 4.019.065 7.808.065 8.952 0 1.271-.881 2.759-3.286 2.312C14.968 89.396 1.188 71.37 1.188 50.125c0-26.561 21.533-48.089 48.089-48.089 26.558 0 48.088 21.528 48.088 48.089-.001 21.241-13.761 39.257-32.858 45.624z\"/>",
    "attrs": {
      "version": "1.2",
      "baseProfile": "tiny",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});