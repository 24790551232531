define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path stroke-miterlimit=\"10\" d=\"M99.085 22.322c1.22 1.228 1.22 3.245 0 4.466L39.22 86.614a3.135 3.135 0 01-4.427 0l-8.85-8.932a1560.444 1560.444 0 00-4.428-4.466L.927 51.59A3.292 3.292 0 010 49.316c0-.802.326-1.572.927-2.186l9.02-8.123a3.082 3.082 0 012.235-.927c.79.006 1.584.32 2.186.927l20.418 20.82a3.123 3.123 0 004.428 0l46.6-46.437a3.106 3.106 0 014.415 0l8.854 8.932z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});