define("ember-svg-jar/inlined/bp-projections-emblem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st1{fill:#1ecad3}</style><path class=\"st1\" d=\"M141.9 123.9c-1 .2-2 .3-3.1.3-10.1 0-18.3-8.2-18.3-18.3 0-.8.1-1.6.2-2.3-18.2-14.3-39.9-24.6-64.2-29-8.7-1.6-17.3-2.2-25.8-2.2v128.2c9.7.2 17.9 8.3 18.3 17.9h127.7c0-35.5-12.9-68.7-34.8-94.6z\" opacity=\".25\"/><path class=\"st1\" d=\"M173.2 79.7c2.4 0 4.9-.9 6.6-2.7 3.6-3.6 3.6-9.6 0-13.3-3.6-3.7-9.5-3.7-13.2 0-3.6 3.6-3.6 9.6 0 13.3 1.9 1.7 4.3 2.7 6.6 2.7zm46.3-62.1L176.7 29c-3.9 1-5.2 5.9-2.4 8.8l31.3 31.3c2.9 2.9 7.7 1.5 8.8-2.4L225.9 24c1.1-3.9-2.5-7.5-6.4-6.4zm-21.7 192.2H56.5c-.8-2.3-1.8-4.4-3.2-6.5-.3-.4-.7-.8-1-1.2l70.6-73.8c3.3 2.3 7 4 11.1 4.7 1.6.3 3.3.4 5 .4 13.4 0 24.8-9.6 27.2-22.7 1.3-7.3-.3-14.6-4.5-20.7s-10.5-10.2-17.8-11.5c-1.6-.3-3.3-.4-5-.4-13.4 0-24.8 9.6-27.2 22.7-1.3 7.3.3 14.6 4.5 20.7l.1.1-71 74.2c-1.8-1.1-3.8-2.2-5.8-2.9V36.2c0-4.6-4.2-8.4-9.4-8.4s-9.4 3.8-9.4 8.4v157c-8.9 3.3-15.7 11.1-17.5 21-1.3 7.3.3 14.6 4.5 20.7s10.5 10.2 17.8 11.5c1.6.3 3.3.4 5 .4 11.8 0 22.1-7.5 26-18.3h141.3c4.6 0 8.4-4.2 8.4-9.4 0-5.1-3.8-9.3-8.4-9.3zm-76.9-107.3c1.6-8.8 9.3-15.1 18-15.1 1.1 0 2.2.1 3.3.3A18.3 18.3 0 01157 109c-1.6 8.8-9.3 15.1-18 15.1-1.1 0-2.2-.1-3.3-.3-2.3-.4-4.3-1.3-6.2-2.4-2.7-1.7-4.9-4-6.5-6.8-2-3.5-2.9-7.7-2.1-12.1zM48.5 222.4c-1.6 8.8-9.3 15.1-18 15.1-1.1 0-2.2-.1-3.3-.3a18.3 18.3 0 01-14.8-21.3c1.6-8.8 9.3-15.1 18-15.1 1.1 0 2.2.1 3.3.3 1.8.3 3.4.9 4.9 1.7 1.3.7 2.5 1.5 3.6 2.4.4.3.7.7 1 1 .7.7 1.3 1.3 1.8 2.1.7.9 1.3 1.8 1.8 2.9.2.4.4.8.5 1.2 1.4 3.1 1.9 6.5 1.2 10z\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 247.8 248.9",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});