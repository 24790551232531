define("ember-svg-jar/inlined/bathroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.045 3.226c3.384-.124 4.876 3.048 5.007 3.979L6.15 8.596c-.122-.87.369-3.88 3.035-4.977A1.974 1.974 0 007.276 2.15H4.422c-1.09 0-1.973.883-1.973 1.973v8.027H.55V4.123A3.873 3.873 0 014.422.25h2.854c1.83 0 3.364 1.27 3.769 2.976z\"/><path d=\"M6.68 10.721l.032.231a1.1 1.1 0 002.179-.306l-.032-.231a1.1 1.1 0 00-2.18.306zm3.697-.52l.032.232a1.1 1.1 0 002.179-.307l-.032-.23a1.1 1.1 0 00-2.18.305zm3.697-.519l.032.23a1.1 1.1 0 002.179-.305l-.032-.231a1.1 1.1 0 00-2.18.306z\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "17",
      "height": "13",
      "viewBox": "0 0 17 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});