define("ember-svg-jar/inlined/question-dark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<circle cx=\"7\" cy=\"8\" r=\"7\" fill=\"#333333\"/>\n<path d=\"M7.619 6.368C7.619 6.885 7.399 7.094 6.992 7.446C6.277 8.062 6.002 8.216 6.002 8.931V9.745H7.311V9.184C7.311 8.788 7.333 8.766 7.718 8.425C8.433 7.798 8.939 7.336 8.939 6.368C8.939 5.147 7.916 4.168 6.596 4.168C4.704 4.168 4.176 5.961 4.176 5.961L5.298 6.478C5.298 6.478 5.65 5.422 6.596 5.422C7.146 5.422 7.619 5.851 7.619 6.368ZM5.793 11.263C5.793 11.747 6.178 12.132 6.662 12.132C7.146 12.132 7.531 11.747 7.531 11.263C7.531 10.779 7.146 10.394 6.662 10.394C6.178 10.394 5.793 10.779 5.793 11.263Z\" fill=\"white\"/>\n",
    "attrs": {
      "width": "14",
      "height": "15",
      "viewBox": "0 0 14 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});