define("ember-svg-jar/inlined/location-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.4.8c2.386 0 5.4 1.913 5.4 5.39 0 2.317-1.8 5.663-5.4 10.039C1.8 11.853 0 8.507 0 6.189 0 2.714 3.014.8 5.4.8zm0 2.695A2.697 2.697 0 002.7 6.19a2.697 2.697 0 002.7 2.694c1.491 0 2.7-1.206 2.7-2.694a2.697 2.697 0 00-2.7-2.695z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "11",
      "height": "17",
      "viewBox": "0 0 11 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});