define("ember-svg-jar/inlined/relay-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M100.2 29H49.1c-5.1 0-9.3 4.2-9.3 9.3h51.3c7.7 0 14 6.3 14 14v51.3c5.1 0 9.3-4.2 9.3-9.3V43.2c0-6.7-7.5-14.2-14.2-14.2z\"/><path d=\"M71.5 1.5C33.1 1.5 1.6 33 1.6 71.4s31.5 69.9 69.9 69.9 69.9-31.5 69.9-69.9c0-38.7-31.5-69.9-69.9-69.9zm0 132.8c-34.7 0-62.9-28.2-62.9-62.9S36.8 8.5 71.5 8.5s62.9 28.2 62.9 62.9-28.4 62.9-62.9 62.9z\"/><path d=\"M86 47.8H34.9c-5.1 0-9.3 4.2-9.3 9.3v46c10-3 21.5-13.9 29.4-21.9.3-.3.5-.4.7-.5l-4.9-4.9c-1.5-1.5-.8-4 1.2-4.5l22.1-5.9c2-.5 3.9 1.3 3.3 3.3l-5.9 22.1c-.5 2-3.1 2.7-4.5 1.2l-4.8-4.8c-.1.1-.1.2-.2.3-7.9 7.9-19.3 19.7-22.3 29.7H86c5.1 0 9.3-4.2 9.3-9.3V57.1c0-5.1-4.2-9.3-9.3-9.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 143 143"
    }
  };
  _exports.default = _default;
});