define("ember-svg-jar/inlined/play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M48.477 27.432L5.669.911C2.549-1.128 0 .372 0 4.257v51.486c0 3.886 2.549 5.386 5.669 3.347l42.808-26.521S50 31.503 50 30.002c0-1.498-1.523-2.57-1.523-2.57z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "50",
      "height": "60",
      "viewBox": "0 0 50 60"
    }
  };
  _exports.default = _default;
});