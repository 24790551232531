define("ember-svg-jar/inlined/auto-booking-review-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\".9\" y=\"1.9\" width=\"12.2\" height=\"12.2\" rx=\"1.1\" fill=\"#fff\" stroke=\"#A8A8A8\" stroke-width=\"1.8\"/><path d=\"M3.5 5h7M3.5 7h7M3.5 9h7M3.5 11h7\" stroke=\"#A8A8A8\" stroke-linecap=\"round\"/><circle cx=\"9\" cy=\"9\" r=\"3.5\" fill=\"#fff\"/><path d=\"M10 10l5 5\" stroke=\"#A8A8A8\" stroke-linecap=\"round\"/><circle cx=\"9\" cy=\"9\" r=\"2.625\" fill=\"#fff\" stroke=\"#A8A8A8\" stroke-width=\".75\"/><path d=\"M8 9.2l.952.761a.05.05 0 0 0 .076-.016L10 8\" stroke=\"#A8A8A8\" stroke-width=\".5\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "fill": "none"
    }
  };
  _exports.default = _default;
});