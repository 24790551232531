define("ember-svg-jar/inlined/cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path stroke=\"#000\" stroke-miterlimit=\"10\" d=\"M98.877 88.005a3.839 3.839 0 000-5.427L69.005 52.707c-1.482-1.489-1.482-3.938 0-5.436l29.857-29.879a3.84 3.84 0 000-5.428L87.985 1.117c-1.482-1.489-3.934-1.489-5.432 0L52.712 30.995a3.859 3.859 0 01-5.43 0L17.409 1.141a3.845 3.845 0 00-5.432 0L1.117 12.012c-1.49 1.48-1.49 3.93 0 5.428l29.88 29.854c1.49 1.489 1.49 3.938 0 5.435L1.14 82.6c-1.49 1.498-1.49 3.938 0 5.428l10.869 10.855c1.49 1.489 3.94 1.489 5.432 0l29.842-29.871a3.853 3.853 0 015.43-.008l29.865 29.863c1.497 1.48 3.947 1.48 5.431 0l10.868-10.862z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});