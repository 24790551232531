define("ember-svg-jar/inlined/relay/settings-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M5.72004 0L5.34437 1.92606C4.71791 2.16295 4.13865 2.4939 3.62999 2.91145L1.77996 2.2734L0 5.35927L1.48032 6.6458C1.42784 6.96786 1.39386 7.29579 1.39386 7.63268C1.39386 7.96957 1.42784 8.29749 1.48032 8.61956L0 9.90608L1.77996 12.992L3.62999 12.3539C4.13865 12.7715 4.71791 13.1024 5.34437 13.3393L5.72004 15.2654H9.27996L9.65563 13.3393C10.2821 13.1024 10.8614 12.7715 11.37 12.3539L13.22 12.992L15 9.90608L13.5197 8.61956C13.5722 8.29749 13.6061 7.96957 13.6061 7.63268C13.6061 7.29579 13.5722 6.96786 13.5197 6.6458L15 5.35927L13.22 2.2734L11.37 2.91145C10.8614 2.4939 10.2821 2.16295 9.65563 1.92606L9.27996 0H5.72004ZM7.5 4.57961C9.18606 4.57961 10.5531 5.94662 10.5531 7.63268C10.5531 9.31874 9.18606 10.6857 7.5 10.6857C5.81394 10.6857 4.44693 9.31874 4.44693 7.63268C4.44693 5.94662 5.81394 4.57961 7.5 4.57961Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 15 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});