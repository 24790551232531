define("ember-svg-jar/inlined/cal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle stroke=\"#56C5CE\" stroke-width=\"5\" stroke-miterlimit=\"10\" cx=\"49.959\" cy=\"50.079\" r=\"46.5\" fill=\"none\"/><path fill=\"#56C5CE\" d=\"M68.3 28.746H30.967c-2.934 0-5.333 2.4-5.333 5.333v32c0 2.934 2.4 5.333 5.333 5.333H68.3c2.934 0 5.333-2.399 5.333-5.333v-32c0-2.933-2.4-5.333-5.333-5.333zm0 37.333H30.967V41.746H68.3v24.333zm-20.366-5.024c-.434 0-.844-.203-1.107-.551l-3.561-4.703a1.388 1.388 0 112.216-1.677l2.342 3.094 5.89-9.459a1.389 1.389 0 112.359 1.468L49.112 60.4c-.24.389-.657.633-1.115.653l-.063.002z\"/>",
    "attrs": {
      "version": "1",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});