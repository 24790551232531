define("ember-svg-jar/inlined/beta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <rect y=\"0.5\" width=\"45\" height=\"26\" rx=\"5\" fill=\"#ff6700\"/>\n  <path d=\"M7.036 18.5V8.7H10.214C11.81 8.7 13.336 9.442 13.336 11.5C13.336 12.69 12.454 13.264 12.314 13.306C12.454 13.334 13.84 13.838 13.84 15.602C13.84 17.324 12.412 18.5 10.564 18.5H7.036ZM10.508 16.904C11.432 16.904 12.132 16.526 12.132 15.602C12.132 14.678 11.432 14.3 10.508 14.3H8.716V16.904H10.508ZM8.716 12.704H10.158C11.096 12.704 11.628 12.354 11.628 11.5C11.628 10.646 10.984 10.296 10.158 10.296H8.716V12.704ZM17.0584 10.296V12.676H20.9784V14.272H17.0584V16.904H21.3984V18.5H15.3784V8.7H21.3144V10.296H17.0584ZM24.9676 18.5V10.296H22.2656V8.7H29.3496V10.296H26.6476V18.5H24.9676ZM29.1972 18.5L33.0052 8.7H34.7132L38.5212 18.5H36.8272L35.9312 16.176H31.7872L30.8912 18.5H29.1972ZM33.8592 10.814L32.3892 14.636H35.3292L33.8592 10.814Z\" fill=\"white\"/>\n",
    "attrs": {
      "width": "45",
      "height": "27",
      "viewBox": "0 0 45 27",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "stroke-width: 0px;"
    }
  };
  _exports.default = _default;
});