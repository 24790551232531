define("ember-svg-jar/inlined/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#5BC5CE\" d=\"M9.232 4.158H5.786V.71c0-.453-.367-.492-.821-.492s-.822.039-.822.492v3.447H.696c-.453 0-.492.367-.492.821s.039.822.492.822h3.447v3.448c0 .453.367.492.821.492s.821-.039.821-.492V5.8h3.447c.453 0 .492-.367.492-.821.001-.453-.038-.821-.492-.821z\"/>",
    "attrs": {
      "version": "1",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10"
    }
  };
  _exports.default = _default;
});