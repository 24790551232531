define("ember-svg-jar/inlined/card-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"path-1-inside-1\" fill=\"white\"><rect width=\"16\" height=\"16\" rx=\"1\"/></mask><rect width=\"16\" height=\"16\" rx=\"1\" fill=\"none\" stroke-width=\"4\" stroke-linejoin=\"round\" mask=\"url(#path-1-inside-1)\"/><path d=\"M5 8H11V11.5C11 11.7761 10.7761 12 10.5 12H5.5C5.22386 12 5 11.7761 5 11.5V8Z\"/><path d=\"M7 9.25C7 9.11193 7.11193 9 7.25 9H8.75C8.88807 9 9 9.11193 9 9.25V12H7V9.25Z\" fill=\"white\"/><path d=\"M7.68765 4.24988C7.87026 4.10379 8.12974 4.10379 8.31235 4.24988L11.887 7.10957C12.256 7.40482 12.0472 8 11.5746 8H4.42539C3.95275 8 3.74397 7.40482 4.11304 7.10957L7.68765 4.24988Z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});