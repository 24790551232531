define("ember-svg-jar/inlined/go_to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n    <title>Export</title>\n    <g id=\"Search-history\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\">\n        <g id=\"Signal-search-history-page\" transform=\"translate(-1289.000000, -83.000000)\" stroke=\"#FFFFFF\" stroke-width=\"1.82608696\">\n            <g id=\"Group-20\" transform=\"translate(1274.000000, 71.000000)\">\n                <g id=\"Export-icon\" transform=\"translate(16.000000, 12.478261)\">\n                    <g id=\"Group\" transform=\"translate(-0.000000, 0.000000)\">\n                        <path d=\"M13.3913043,10.590268 C13.3913043,11.4949613 13.3913043,12.3996545 13.3913043,13.3043478 C13.3913043,13.9766945 12.8462597,14.5217391 12.173913,14.5217391 L1.2173913,14.5217391 C0.545044652,14.5217391 -9.50350909e-13,13.9766945 -9.50350909e-13,13.3043478 L-9.50350909e-13,2.34782609 C-9.50350909e-13,1.67547943 0.545044652,1.13043478 1.2173913,1.13043478 C2.13495044,1.13043478 3.05250957,1.13043478 3.9700687,1.13043478\" id=\"Path\"/>\n                        <path d=\"M5.5,9.02173913 L12.6956522,1.82608696 M14.0217391,6.5 L14.0217391,0.5 M8.02173913,0.5 L14.0217391,0.5\" id=\"Combined-Shape\"/>\n                    </g>\n                </g>\n            </g>\n        </g>\n    </g>\n",
    "attrs": {
      "width": "16px",
      "height": "16px",
      "viewBox": "0 0 16 16",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});