define("ember-svg-jar/inlined/listing-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M122.7 105.9l-9.5 30 32.9-5.6 107.6-104L230.3 2 122.7 105.9zM152.9 0v34.4H59.5c-12.5 0-22.7 11.1-22.7 24.7v137.8c0 13.6 10.2 24.7 22.7 24.7h112.2c12.5 0 22.7-11.1 22.7-24.7v-63.7h34.4v63.7c0 32.6-25.6 59.1-57.1 59.1H59.5C28 256 2.3 229.5 2.3 196.9V59.1C2.3 26.5 28 0 59.5 0h93.4z\"/>",
    "attrs": {
      "viewBox": "0 0 256 256"
    }
  };
  _exports.default = _default;
});