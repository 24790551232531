define("ember-svg-jar/inlined/pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M62.277 0c-7.043 0-12.742 2.188-12.742 9.292v81.431c0 7.09 5.699 9.277 12.742 9.277C69.301 100 75 97.812 75 90.703V9.292C75 2.188 69.302 0 62.277 0zM12.742 0C5.699 0 0 2.188 0 9.292v81.431C0 97.812 5.699 100 12.742 100c7.024 0 12.728-2.188 12.728-9.297V9.292C25.469 2.188 19.766 0 12.742 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "75",
      "height": "100",
      "viewBox": "0 0 75 100"
    }
  };
  _exports.default = _default;
});