define("ember-svg-jar/inlined/homeaway-mini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#0C6DB6\" d=\"M100 37.1L72 5.7H27L.5 37h9.3v33.3l-7.1.9s-3.4.6-2.6 4.3c.7 2.3 3.5 2.2 3.5 2.2l6.2-.7v9.2l44.5 8 35.4-8-.1-49.2H100zM62.9 43l9.7-.5v12.9l-9.8.8.1-13.2zM13 83.8v-7l19.4-2.6s3.3 0 3.1-3.7c-.2-3.3-3.8-2.8-3.8-2.8L13 69.9l.1-32.8 20.2-25.6 21.1 25.6v54.2L13 83.8zm49.8-10.1V60.4l9.8-.8.1 13.1-9.9 1zm21.9-2.1l-8.1.8v-13l8.1-.6v12.8zm-8.1-16.4V42.3l8.1-.3v12.6l-8.1.6zM41.9 37.5c-2.3-2.7-5.4-4.3-8.9-4.3h-.5c-3.4.2-6.4 2-8.5 4.7-2.1 2.7-3.3 6.3-3.3 10.3v1c.2 4 1.7 7.7 4 10.3 2.3 2.6 5.4 4.3 8.8 4.3h.5c3.4-.2 6.4-2 8.5-4.7 2.1-2.7 3.3-6.3 3.3-10.3v-1c-.2-4-1.6-7.7-3.9-10.3zm-1.3 20c-1.7 2.2-4 3.6-6.6 3.7h-.4c-2.6 0-5-1.3-6.9-3.5-1.9-2.2-3.2-5.3-3.4-8.8V48c0-3.4 1.1-6.5 2.8-8.7 1.7-2.2 4-3.6 6.6-3.7h.4c2.6 0 5 1.3 6.9 3.5 1.9 2.2 3.2 5.3 3.4 8.8v.9c0 3.4-1.1 6.5-2.8 8.7zm-7.2.5c-3.7.2-7-3.8-7.2-9.1-.3-5.2 2.5-9.6 6.2-9.8-2.2 1.4-3.6 5.2-3.4 9.6.2 4.5 2 8.1 4.4 9.3z\"/>",
    "attrs": {
      "version": "1.2",
      "baseProfile": "tiny",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});