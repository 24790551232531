define("ember-svg-jar/inlined/cards/card-visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <path fill=\"#F6F6F6\" d=\"M0 9h160v80h-160z\"/>\n  <path d=\"M148 0h-140c-4 0-8 4-8 8v12h160v-8c0-8-4-12-12-12zm0 0\" fill=\"#265697\"/>\n  <path d=\"M0 80v8c0 8 4 12 12 12h136c8 0 12-4 12-12v-8h-160zm0 0\" fill=\"#D97B16\"/>\n  <path d=\"M111.615 29.782c-2.243-.86-5.756-1.782-10.144-1.782-11.184 0-19.062 5.755-19.129 14.003-.063 6.097 5.624 9.498 9.918 11.528 4.406 2.08 5.887 3.406 5.866 5.263-.028 2.844-3.518 4.144-6.771 4.144-4.53 0-6.937-.643-10.654-2.227l-1.459-.674-1.589 9.499c2.643 1.184 7.532 2.211 12.608 2.264 11.898 0 19.621-5.689 19.709-14.497.043-4.827-2.973-8.5-9.503-11.528-3.956-1.963-6.379-3.273-6.353-5.26 0-1.764 2.051-3.65 6.482-3.65 3.701-.059 6.382.766 8.471 1.626l1.014.49 1.534-9.196M140.638 28.778h-8.746c-2.709 0-4.737.756-5.927 3.519l-16.81 38.885h11.885s1.943-5.228 2.383-6.376c1.299 0 12.845.018 14.496.018.339 1.485 1.377 6.358 1.377 6.358h10.503l-9.161-42.404zm-13.957 27.349c.936-2.445 4.509-11.861 4.509-11.861-.067.113.929-2.457 1.501-4.05l.765 3.658s2.167 10.128 2.62 12.252h-9.395zm0 0M59.25 71.169l7.077-42.431h11.319l-7.081 42.431h-11.314zm0 0M49.757 28.768l-11.081 28.935-1.181-5.88c-2.063-6.778-8.49-14.122-15.676-17.799l10.132 37.108 11.975-.014 17.819-42.35h-11.989\" fill=\"#265697\"/>\n  <path d=\"M28.396 28.742h-18.251l-.144.883c14.199 3.512 23.595 12 27.495 22.198l-3.969-19.499c-.685-2.687-2.672-3.489-5.131-3.582\" fill=\"#D97B16\"/>\n",
    "attrs": {
      "height": "100",
      "viewBox": "0 0 160 100",
      "width": "160",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});