define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.9 28.9L23 16 9.9 3.1 11 2l14 14-14 14-1.1-1.1z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});