define("ember-svg-jar/inlined/relay/reservations/reservation-placeholder-part-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M52,14H0V0h52V14z M142,22H0v14h142V22z\"/>",
    "attrs": {
      "viewBox": "0 0 142 36"
    }
  };
  _exports.default = _default;
});