define("ember-google-maps/components/g-map/info-window", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/info-window", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers"], function (_exports, _mapComponent, _infoWindow, _optionsAndEvents, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.InfoWindowAPI = InfoWindowAPI;
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function InfoWindowAPI(source) {
    var mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      actions: {
        open: function open() {
          return source.open();
        },
        close: function close() {
          return source.close();
        }
      }
    });
  }
  /**
   * A wrapper for the google.maps.InfoWindow class.
   *
   * @class InfoWindow
   * @namespace GMap
   * @module ember-google-maps/components/g-map/info-window
   * @extends GMap.MapComponent
   */


  var _default = _mapComponent.default.extend({
    layout: _infoWindow.default,
    _type: 'infoWindow',
    isOpen: false,
    _cachedIsOpen: false,
    position: _helpers.position,
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat(_toConsumableArray(_optionsAndEvents.ignoredOptions), ['isOpen', 'target', 'content'])),
    _createOptions: function _createOptions(options) {
      var newOptions = {
        content: undefined
      };

      if (!Ember.get(this, 'target')) {
        newOptions.position = Ember.get(this, 'position');
      }

      if (Ember.get(this, 'isOpen')) {
        newOptions.content = this._getContent();
      }

      return _objectSpread({}, options, {}, newOptions);
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.publicAPI = InfoWindowAPI(this);
    },
    _addComponent: function _addComponent(options) {
      return Ember.RSVP.resolve(Ember.set(this, 'mapComponent', new google.maps.InfoWindow(options)));
    },
    _didAddComponent: function _didAddComponent() {
      this._openOrClose();

      this._super.apply(this, arguments);
    },
    _updateComponent: function _updateComponent(mapComponent, options) {
      mapComponent.setOptions(options);

      this._openOrClose();
    },
    _openOrClose: function _openOrClose() {
      var isOpen = Ember.get(this, 'isOpen');
      var isOpenChanged = this._cachedIsOpen !== isOpen;

      if (isOpenChanged && isOpen) {
        this.open();
      } else if (isOpenChanged && !isOpen) {
        this.close();
      }

      Ember.set(this, '_cachedIsOpen', isOpen);
    },
    _getContent: function _getContent() {
      if (this.content) {
        return this.content;
      }

      var content = document.createElement('div');
      Ember.set(this, '_targetPane', content);
      Ember.set(this, 'content', content);
      return content;
    },
    open: function open() {
      var _this = this;

      if (this.mapComponent) {
        google.maps.event.addListenerOnce(this.mapComponent, 'closeclick', function () {
          Ember.set(_this, 'isOpen', false);
        });
        this.mapComponent.open(Ember.get(this, 'map'), Ember.get(this, 'target'));
      }
    },
    close: function close() {
      if (this.mapComponent) {
        this.mapComponent.close();
      }
    }
  });

  _exports.default = _default;
});