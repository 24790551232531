define("ember-svg-jar/inlined/list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 0H7c-.6 0-1 .325-1 .813v1.625c0 .487.4.812 1 .812h8c.6 0 1-.325 1-.813V.813C16 .325 15.6 0 15 0zm0 4.875H7c-.6 0-1 .325-1 .813v1.625c0 .487.4.812 1 .812h8c.6 0 1-.325 1-.813V5.688c0-.487-.4-.812-1-.812zm0 4.875H7c-.6 0-1 .325-1 .813v1.624c0 .488.4.813 1 .813h8c.6 0 1-.325 1-.813v-1.624c0-.488-.4-.813-1-.813zM3 0H1C.4 0 0 .325 0 .813v1.625c0 .487.4.812 1 .812h2c.6 0 1-.325 1-.813V.813C4 .325 3.6 0 3 0zm0 4.875H1c-.6 0-1 .325-1 .813v1.625c0 .487.4.812 1 .812h2c.6 0 1-.325 1-.813V5.688c0-.487-.4-.812-1-.812zM3 9.75H1c-.6 0-1 .325-1 .813v1.624C0 12.675.4 13 1 13h2c.6 0 1-.325 1-.813v-1.624c0-.488-.4-.813-1-.813z\"/><path d=\"M15.5 0h-14C.9 0 .5.371.5.929V12.07c0 .558.4.929 1 .929h14c.6 0 1-.371 1-.929V.93c0-.558-.4-.929-1-.929zm-13 5.571h8V7.43h-8V5.57zm8-3.714v1.857h-8V1.857h8zm-8 7.429h8v1.857h-8V9.286zm12 1.857h-2V1.857h2v9.286z\" fill=\"#343F53\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 13"
    }
  };
  _exports.default = _default;
});