define("ember-svg-jar/inlined/bed-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"><path d=\"M47 27.626H1l3.778-6.791h38.444zM4.797 20.763v-8.908s17.585-11.391 38.406 0v8.908\"/><path d=\"M9.324 13.778h12.559v7.058H9.324zM1 27.626v13.581h5.914v-3.529h34.172v3.529H47V27.626zM25.826 13.778h12.559v7.058H25.826z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 48 48"
    }
  };
  _exports.default = _default;
});