define("ember-google-maps/components/g-map/overlay", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/overlay", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers"], function (_exports, _mapComponent, _overlay, _optionsAndEvents, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var READY = _mapComponent.MapComponentLifecycleEnum.READY;
  /**
   * A wrapper for the google.maps.Overlay class.
   *
   * @class Overlay
   * @namespace GMap
   * @module ember-google-maps/components/g-map/overlay
   * @extends GMap.MapComponent
   */

  var _default = _mapComponent.default.extend({
    layout: _overlay.default,
    _type: 'overlay',
    position: _helpers.position,
    paneName: 'overlayMouseTarget',
    zIndex: 'auto',
    _targetPane: null,
    _contentId: Ember.computed(function () {
      return "ember-google-maps-overlay-".concat(Ember.guidFor(this));
    }),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([].concat(_toConsumableArray(_optionsAndEvents.ignoredOptions), ['paneName', 'zIndex'])),
    init: function init() {
      this._super(arguments); // Remove for 4.0


      (false && Ember.warn("\nThe `innerContainerStyle` option has been removed. See the docs for examples of how to offset overlays relative to their coordinates.\nhttps://ember-google-maps.sandydoo.me/docs/overlays/", typeof this.innerContainerStyle === 'undefined', {
        id: 'inner-container-style-removed'
      }));
    },
    _addComponent: function _addComponent() {
      var _this2 = this;

      var isFinishedDrawing = Ember.RSVP.defer();
      var Overlay = new google.maps.OverlayView();

      function onAdd() {
        if (this.isDestroyed) {
          return;
        }

        var panes = this.mapComponent.getPanes();
        Ember.set(this, '_targetPane', panes[this.paneName]);
      }

      function initialDraw() {
        var _this = this;

        if (this.isDestroyed) {
          return;
        }

        var contentId = Ember.get(this, '_contentId');
        var content = this.fetchOverlayContent(contentId);
        (false && !(Boolean(content)) && Ember.assert('No content', Boolean(content)));
        Ember.set(this, 'content', content);
        (this.mapComponent.draw = Ember.run.bind(this, function () {
          return Ember.run.scheduleOnce('render', _this, 'draw');
        }))();
        isFinishedDrawing.resolve(this.mapComponent);
      }

      Overlay.onAdd = Ember.run.bind(this, function () {
        return Ember.run.once(_this2, onAdd);
      });
      Overlay.draw = Ember.run.bind(this, function () {
        return Ember.run.scheduleOnce('afterRender', _this2, initialDraw);
      });
      Overlay.onRemove = Ember.run.bind(this, 'destroy');
      Ember.set(this, 'mapComponent', Overlay);
      this.mapComponent.setMap(this.map);
      return isFinishedDrawing.promise;
    },
    _didAddComponent: function _didAddComponent(_, options, events) {
      var _this3 = this;

      var payload = {
        map: this.map,
        publicAPI: this.publicAPI
      };
      (0, _optionsAndEvents.addEventListeners)(this.content, events, payload).forEach(function (_ref) {
        var name = _ref.name,
            remove = _ref.remove;
        return _this3._eventListeners.set(name, remove);
      });
      return Ember.RSVP.resolve();
    },
    _updateComponent: function _updateComponent() {
      if (this.mapComponentLifecycle === READY) {
        this.mapComponent.draw();
      }
    },
    draw: function draw() {
      if (this.isDestroyed) {
        return;
      }

      var overlayProjection = this.mapComponent.getProjection(),
          position = Ember.get(this, 'position'),
          point = overlayProjection.fromLatLngToDivPixel(position),
          zIndex = Ember.get(this, 'zIndex');
      this.content.style.cssText = "\n      position: absolute;\n      left: 0;\n      top: 0;\n      height: 0;\n      z-index: ".concat(zIndex, ";\n      transform: translateX(").concat(point.x, "px) translateY(").concat(point.y, "px);\n    ");
    },
    fetchOverlayContent: function fetchOverlayContent(id) {
      return document.getElementById(id);
    }
  });

  _exports.default = _default;
});