define("ember-svg-jar/inlined/home-iambnb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M396.095 100.587c-6.575 12.9-26.053 19.678-38.695 9.457v3.556h-13.2V28.9h13.6v36.2c4.1-2.6 7.604-5.05 11.904-5.45 14-1.2 27.554 9.867 29.165 21.28 1.61 11.41-1.228 16.624-2.775 19.657zM386 87.6c0-7.7-6.7-14.7-14.1-14.6-7.2.1-13.8 6.9-13.9 14.3-.1 7.8 6.4 14.5 14.1 14.4 7.5-.1 13.8-6.6 13.9-14.1zM174.33 60.224C191.74 58.58 204.4 69.2 205 84.7c.4 9.5.1 19 .1 28.8h-14.2c0-8.8.1-17.6 0-26.4-.1-7-4.2-12.5-9.8-13.2-7.3-1-13 4-13.3 12-.2 7.5-.1 15-.1 22.5v5.2h-14.6V87.1c0-7.9-4.4-13.1-11.1-13.2-7-.1-11.8 5.1-11.9 13.1-.1 8.8 0 17.6 0 26.6h-14.426V61.2H129.5v3.54c9.94-7.352 21.225-6.067 30.94 3.5 0 0 7.612-7.423 13.89-8.016zM216.6 113.6V28.9H230v36.5c7.5-6 15.2-6.8 23.2-4.3 5.7 1.8 10.3 5.3 13.7 10.3 7.6 11.1 6.8 25-2.2 35-8.3 9.2-24.206 11.463-34.7 4.108v3.092h-13.4zm41.6-26c.1-7.7-6.6-14.7-14-14.7-7.1.1-13.9 7-14.1 14.2-.1 7.7 6.5 14.5 14.1 14.5 7.6.1 13.9-6.3 14-14zm-167.6 26v-3.724c-.644.234-.72.424-1.102.7-.918.667-1.595 1.13-1.595 1.13s-5.546 2.996-8.273 3.252c-12.987 1.224-28.134-5.707-30.348-24.796C48.102 80 52.8 69.8 62.4 63.6c8.4-5.4 18.605-6.01 28.2 2.02V61h13.9v52.6H90.6zM63 87.3c-.1 8 6.2 14.5 13.9 14.4 7.4-.1 13.9-6.6 13.9-14.1.2-7.6-6.7-14.7-14-14.6-7.4 0-13.7 6.6-13.8 14.3zm232.2-26.5v3.96c12.367-12.103 36.9-.53 36.9 18.49v30.887h-13.8c0-8.9.608-17.154 0-26.737-.608-9.583-5.1-13.1-11.4-13.5-6.5-.4-11.7 5.7-11.7 13.5-.1 8.5 0 17.837 0 26.737h-14.532V60.8H295.2zM164.4 18V2.1h18.2v24.714c7.9 3.9 15.5 8.213 23.2 12.113V55.4c-25.4-12.5-50.5-24.8-76.1-37.4-26.3 13-53 26-80.2 39.4V40.3C61.7 34.3 129.7.108 129.7.108 137.61 5.16 164.4 18 164.4 18zM.107 30.9H15.8v82.7H0C0 86 0 58.4.107 30.9z\" fill=\"#CDCDCD\" opacity=\".569\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "400",
      "height": "116",
      "viewBox": "0 0 400 116",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});