define("ember-svg-jar/inlined/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.531 1c-1.797 0-3.367.926-4.062 2.688 1.46 1.203 2.469 3.066 2.469 5.593 0 2.696-1.274 5.16-2.594 6.75 2.105.797 5.402 2.395 6.344 4.844 3.316-.184 6.28-.852 6.28-2.75V17.5c0-1.738-3.034-3.441-5.718-4.344-.121-.039-.89-.226-.406-1.719 1.261-1.316 2.125-3.445 2.125-5.53C21.969 2.694 19.973 1 17.53 1zM8.97 4.094c-2.602 0-4.844 1.777-4.844 5.187 0 2.23 1.059 4.508 2.406 5.906.524 1.399-.43 2.395-.625 2.47C3.188 18.651 0 20.452 0 22.25v.688c0 2.449 4.672 3 9 3 4.336 0 8.969-.551 8.969-3v-.688c0-1.852-3.207-3.633-6.063-4.594-.133-.043-.953-.914-.437-2.5h-.031c1.34-1.402 2.5-3.652 2.5-5.875 0-3.41-2.372-5.187-4.97-5.187z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 26 26",
      "width": "104",
      "height": "104"
    }
  };
  _exports.default = _default;
});