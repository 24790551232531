define("ember-svg-jar/inlined/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M42 48H6c-3.3 0-6-2.7-6-6V6c0-3.3 2.7-6 6-6h36c3.3 0 6 2.7 6 6v36c0 3.3-2.7 6-6 6zM6 4c-1.1 0-2 .9-2 2v36c0 1.1.9 2 2 2h36c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H6zm6 8h24v4H12v-4zm0 10h24v4H12v-4zm0 10h24v4H12v-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 48 48"
    }
  };
  _exports.default = _default;
});