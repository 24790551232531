define("ember-svg-jar/inlined/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#5BC5CE\" d=\"M100 18.983c-3.678 1.64-7.641 2.735-11.789 3.257 4.24-2.543 7.493-6.589 9.008-11.372a41.25 41.25 0 01-13.005 4.973 20.537 20.537 0 00-14.976-6.467c-11.334 0-20.512 9.187-20.512 20.516 0 1.589.174 3.156.528 4.664-17.057-.853-32.174-8.998-42.292-21.447a20.66 20.66 0 00-2.773 10.329 20.51 20.51 0 009.122 17.071 20.28 20.28 0 01-9.289-2.577v.276c0 9.935 7.077 18.234 16.454 20.124a20.943 20.943 0 01-5.41.707 19.95 19.95 0 01-3.855-.371c2.605 8.15 10.187 14.096 19.164 14.244-7.018 5.514-15.863 8.775-25.479 8.775-1.654 0-3.288-.094-4.895-.273 9.077 5.812 19.857 9.213 31.444 9.213 37.741 0 58.375-31.25 58.375-58.381l-.07-2.64c4-2.882 7.493-6.513 10.25-10.621z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});