define("ember-google-maps/templates/components/g-map/info-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rmUj7s6X",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[35,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"content\",\"publicAPI\",\"if\",\"_targetPane\",\"in-element\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/info-window.hbs"
    }
  });

  _exports.default = _default;
});