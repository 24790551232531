define("ember-svg-jar/inlined/bp-insights-compsets_logo_blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<circle cx=\"10.2826\" cy=\"5.21739\" r=\"4.86957\" fill=\"white\" stroke=\"#BFE8EB\" stroke-width=\"0.695652\"/>\n<circle cx=\"5.71739\" cy=\"5.21739\" r=\"5.21739\" fill=\"#2BA8B3\"/>\n<circle cx=\"10.2826\" cy=\"5.21739\" r=\"4.86957\" fill=\"#A0DDE2\" fill-opacity=\"0.5\" stroke=\"#BFE8EB\" stroke-width=\"0.695652\"/>\n",
    "attrs": {
      "width": "16",
      "height": "11",
      "viewBox": "0 0 16 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});