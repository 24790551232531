define("ember-mapbox-gl/templates/components/mapbox-gl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1jJq16T+",
    "block": "{\"symbols\":[\"&else\",\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"_loader\",\"isLoaded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,2,[[30,[36,3],null,[[\"call\",\"control\",\"image\",\"layer\",\"marker\",\"on\",\"popup\",\"source\",\"instance\"],[[30,[36,2],[\"mapbox-gl-call\"],[[\"obj\"],[[32,0,[\"_loader\",\"map\"]]]]],[30,[36,2],[\"mapbox-gl-control\"],[[\"map\"],[[32,0,[\"_loader\",\"map\"]]]]],[30,[36,2],[\"mapbox-gl-image\"],[[\"map\"],[[32,0,[\"_loader\",\"map\"]]]]],[30,[36,2],[\"mapbox-gl-layer\"],[[\"map\"],[[32,0,[\"_loader\",\"map\"]]]]],[30,[36,2],[\"mapbox-gl-marker\"],[[\"map\",\"MapboxGl\"],[[32,0,[\"_loader\",\"map\"]],[32,0,[\"_loader\",\"MapboxGl\"]]]]],[30,[36,2],[\"mapbox-gl-on\"],[[\"eventSource\"],[[32,0,[\"_loader\",\"map\"]]]]],[30,[36,2],[\"mapbox-gl-popup\"],[[\"map\",\"MapboxGl\"],[[32,0,[\"_loader\",\"map\"]],[32,0,[\"_loader\",\"MapboxGl\"]]]]],[30,[36,2],[\"mapbox-gl-source\"],[[\"map\"],[[32,0,[\"_loader\",\"map\"]]]]],[32,0,[\"_loader\",\"map\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"_loader\",\"error\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[32,0,[\"_loader\",\"error\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"error rendering mapbox-gl\",[32,0,[\"_loader\",\"error\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"log\",\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl.hbs"
    }
  });

  _exports.default = _default;
});