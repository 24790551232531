define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M90 22H71.854l-10-20H38.146l-10 20H10a3 3 0 100 6h7v57c0 7.168 5.832 13 13 13h40c7.168 0 13-5.832 13-13V28h7a3 3 0 100-6zM41.854 8h16.292l7 14H34.854l7-14zM77 85c0 3.86-3.14 7-7 7H30c-3.86 0-7-3.14-7-7V28h54v57z\"/><path d=\"M35 37a3 3 0 00-3 3v40a3 3 0 106 0V40a3 3 0 00-3-3zm15 0a3 3 0 00-3 3v40a3 3 0 106 0V40a3 3 0 00-3-3zm15 0a3 3 0 00-3 3v40a3 3 0 106 0V40a3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});