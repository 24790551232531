define("ember-svg-jar/inlined/connect-pms-label-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"a\" fill=\"#fff\"><use xlink:href=\"#path-1\"/></mask><circle fill=\"#264360\" mask=\"url(#a)\" cx=\"21\" cy=\"21\" r=\"21\"/><g mask=\"url(#a)\"><g transform=\"translate(9 10)\"><path d=\"M8.333 23H1.667A1.669 1.669 0 010 21.333v-6.666C0 13.747.748 13 1.667 13h6.666c.92 0 1.667.748 1.667 1.667v6.666C10 22.253 9.252 23 8.333 23zM23.333 10h-6.666A1.669 1.669 0 0115 8.333V1.667C15 .747 15.748 0 16.667 0h6.666C24.253 0 25 .748 25 1.667v6.666c0 .92-.748 1.667-1.667 1.667z\" fill=\"#43B6C0\" fill-rule=\"nonzero\"/><circle fill=\"#FFF\" cx=\"20\" cy=\"5\" r=\"2\"/><path d=\"M6 19v-1.044c.001 0 4.295-.007 4.723-.219.517-.256.768-.697.768-1.346V7.609c0-.55.137-1.037.4-1.437a2.413 2.413 0 011.004-.856C13.543 5.009 18.927 5 18.997 5L19 6.044c-.005 0-5.213.007-5.64.219-.518.256-.769.697-.769 1.346v8.782c0 1.049-.499 1.863-1.404 2.293C10.539 18.991 6.07 19 6 19z\" fill=\"#FFF\"/><circle fill=\"#FFF\" cx=\"5\" cy=\"18\" r=\"2\"/></g></g></g>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});