define("ember-svg-jar/inlined/disk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M97.064 20.692L79.4 2.956A10.04 10.04 0 0072.286 0H10.047C4.498 0 0 4.505 0 10.062v79.874C0 95.493 4.498 100 10.047 100h79.905C95.501 100 100 95.493 100 89.938V27.8c0-2.664-1.057-5.221-2.936-7.108zM28.96 10.062h39.979v31.574H28.96V10.062zm60.992 79.876H10.047V10.062h8.866v36.605a5.028 5.028 0 005.023 5.031h50.026a5.028 5.028 0 005.022-5.031V16.79L89.952 27.8v62.138zM59.311 14.172a5.029 5.029 0 00-5.023 5.032v13.207a5.029 5.029 0 005.023 5.031 5.027 5.027 0 005.023-5.031V19.204a5.028 5.028 0 00-5.023-5.032z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});