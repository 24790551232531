define("ember-svg-jar/inlined/note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#D8D8D8\" fill-rule=\"evenodd\"><path d=\"M9.5 6.766V10c0 .563-.415 1-.9 1H2.9c-.485 0-.9-.437-.9-1V3c0-.563.415-1 .9-1h4.742V0H2.9C1.287 0 0 1.354 0 3v7c0 1.646 1.287 3 2.9 3h5.7c1.613 0 2.9-1.354 2.9-3V6.766h-2z\" fill-rule=\"nonzero\"/><path d=\"M11.474 0l1.39 1.439-5.465 5.276-1.772.186.383-1.624z\"/></g>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});