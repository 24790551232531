define("ember-svg-jar/inlined/youtube-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M71.43 50c0 1.377-.56 2.38-1.675 3.014L41.183 70.87c-1.153.744-2.362.78-3.627.112-1.228-.67-1.842-1.712-1.842-3.125V32.143c0-1.413.614-2.455 1.842-3.125.596-.297 1.172-.446 1.73-.446.743 0 1.376.187 1.896.56l28.572 17.855c1.116.632 1.675 1.636 1.675 3.013zM100 50c0-3.57-.02-6.36-.056-8.37-.037-2.01-.195-4.55-.475-7.617-.28-3.07-.7-5.813-1.258-8.23-.595-2.717-1.878-5.005-3.85-6.865-1.972-1.86-4.278-2.938-6.92-3.236-8.26-.93-20.74-1.395-37.444-1.395s-29.187.465-37.445 1.395c-2.642.298-4.957 1.377-6.947 3.236-1.99 1.86-3.283 4.148-3.88 6.864C1.21 28.2.81 30.944.53 34.012.25 37.082.092 39.62.055 41.63.02 43.64 0 46.43 0 50s.02 6.36.056 8.37c.037 2.01.195 4.55.475 7.617.28 3.07.7 5.813 1.258 8.23.595 2.717 1.878 5.005 3.85 6.865 1.972 1.86 4.278 2.938 6.92 3.236 8.26.93 20.74 1.395 37.444 1.395s29.187-.465 37.445-1.395c2.642-.298 4.957-1.377 6.947-3.236 1.99-1.86 3.283-4.148 3.88-6.864.52-2.418.92-5.162 1.198-8.23.28-3.07.438-5.608.475-7.618.036-2.01.055-4.8.055-8.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});