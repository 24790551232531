define('ember-highcharts/utils/option-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setDefaultHighChartOptions = setDefaultHighChartOptions;
  /* global Highcharts */
  var localConfig = null;

  function setDefaultHighChartOptions(owner) {
    if (!localConfig) {
      // use options defined in highcharts-configs/application.js if they exist
      var configFactory = owner.factoryFor('highcharts-config:application');

      if (configFactory && configFactory.class) {
        var localConfigBuilder = configFactory.class;
        localConfig = localConfigBuilder(defaultOptions);
      } else {
        localConfig = defaultOptions;
      }
    }

    Highcharts.setOptions(localConfig);
  }

  var defaultOptions = {
    plotOptions: {
      series: {
        shadow: false
      }
    },

    global: {
      timezoneOffset: new Date().getTimezoneOffset()
    },

    credits: {
      enabled: false
    }
  };
});