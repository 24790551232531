define("ember-svg-jar/inlined/linkedin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#218CB5\" d=\"M1.266 97.793V33.298h21.437v64.495H1.266zm10.722-73.301h-.143C4.65 24.492 0 19.54 0 13.35 0 7.022 4.792 2.207 12.128 2.207c7.331 0 11.849 4.816 11.986 11.143 0 6.191-4.655 11.142-12.126 11.142zM34.564 97.793s.285-58.446 0-64.496h21.438v9.351h-.145c2.822-4.402 7.898-10.863 19.463-10.863 14.105 0 24.68 9.218 24.68 29.026v36.982H78.564V63.289c0-8.67-3.102-14.584-10.86-14.584-5.918 0-9.448 3.989-10.993 7.84-.568 1.377-.707 3.305-.707 5.232v36.016h-21.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});