define("ember-svg-jar/inlined/reload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M3.112 3.062c3.467-3.469 9.066-3.51 12.609-.136l1.918-1.917.329 6.067-6.068-.329 2.229-2.227c-2.66-2.492-6.83-2.448-9.417.14-2.642 2.642-2.635 6.931.015 9.581 2.65 2.649 6.939 2.657 9.581.015a6.717 6.717 0 001.899-3.761l2.271.077a8.975 8.975 0 01-2.57 5.283c-3.522 3.521-9.242 3.512-12.774-.021-3.535-3.531-3.545-9.251-.022-12.772z\"/>",
    "attrs": {
      "version": "1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 19"
    }
  };
  _exports.default = _default;
});