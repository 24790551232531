define("ember-svg-jar/inlined/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M23 0A23 23 0 000 23a33.05 33.05 0 004.14 15L21 66.34a2.3 2.3 0 004 0l16.86-28.37a33.05 33.05 0 004.14-15A23 23 0 0023 0zm0 4.6A18.37 18.37 0 0141.4 23a33.07 33.07 0 01-3.52 12.63L23 60.64l-14.88-25A33.07 33.07 0 014.6 23 18.37 18.37 0 0123 4.6zm0 6.9A11.5 11.5 0 1034.5 23 11.54 11.54 0 0023 11.5zm0 4.6a6.9 6.9 0 11-6.9 6.9 6.87 6.87 0 016.9-6.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 46 67.48"
    }
  };
  _exports.default = _default;
});