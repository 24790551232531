define("ember-svg-jar/inlined/bp-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"19.3\" cy=\"19.8\" r=\"12.6\" fill=\"#d9f1fd\" stroke=\"#231f20\" stroke-width=\".5\" stroke-miterlimit=\"10\"/><path d=\"M15.2 22.8c.2 0 .3-.1.3-.3s-.1-.3-.3-.3h-1.7c-.2 0-.3.1-.3.3s.1.3.3.3h1.7zM15.8 17.7c.2 0 .3-.1.3-.3s-.1-.3-.3-.3h-1.7c-.2 0-.3.1-.3.3s.1.3.3.3h1.7zM21.4 21.6c.2 0 .3-.1.3-.3s-.1-.3-.3-.3h-1.7c-.2 0-.3.1-.3.3s.1.3.3.3h1.7zM19.7 13.2c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3h-.6c-.2 0-.3.1-.3.3 0 .2.1.3.3.3h.6zM13.2 23.6c0-.2.1-.3.3-.3h1.7c.2 0 .3.1.3.3v3.7h-.6v-3.4h-1.1v3.4l-.6.6v-4.3zM19.5 23c0-.2.1-.3.3-.3h1.7c.2 0 .3.1.3.3v4.2h-.6v-4h-1.1v4h-.6V23z\"/><path d=\"M12.1 22.5l2.3-2.4 2.3 2.4v4.8h.6v-4.9c0-.1 0-.1-.1-.2l-2.5-2.7c-.1-.1-.3-.1-.4 0l-2.5 2.7c0 .1-.1.1-.1.2v5.5h.6v-5.4z\"/><path d=\"M12.7 16.8l2.3-2.4 2.3 2.4v3.1h.6v-3.2c0-.1 0-.1-.1-.2l-2.5-2.7c-.1-.1-.3-.1-.4 0l-2.5 2.7c0 .1-.1.1-.1.2v5.5l.6-.6v-4.8zM17.2 13.4l2.3-2.4 2.3 2.4V16l.6.6v-3.2c0-.1 0-.1-.1-.2l-2.5-2.7c-.1-.1-.3-.1-.4 0l-2.5 2.7c0 .1-.1.1-.1.2v1h.6v-1zM16.6 22.2h1.7v.6h-1.7z\"/><path d=\"M22.3 16.5h.6v1.7h-.6zM25.1 13.2h.6v6.2h-.6zM26.2 20.5h.6v7.3h-.6zM18.3 20.8l2.3-2.4 2.3 2.4v6.5h.6v-6.6c0-.1 0-.1-.1-.2l-2.5-2.7c-.1-.1-.3-.1-.4 0L18 20.5c0 .1-.1.1-.1.2v6.6h.6v-6.5z\"/><path d=\"M22.6 10.9l2.3 2.3h-3.1v.6h3.8c.3 0 .4-.3.2-.5L23 10.5c-.1-.1-.1-.1-.2-.1h-3.3v.6h3.1zM19.8 14.3l2.3 2.3h-4.8v.6h5.5c.3 0 .4-.3.2-.5l-2.8-2.8c-.1-.1-.1-.1-.2-.1h-5v.6h4.8zM23.7 18.2l2.3 2.3h-3.1v.6h3.8c.3 0 .4-.3.2-.5l-2.8-2.8c-.1-.1-.1-.1-.2-.1h-3.3v.6h3.1zM17.5 19.9h-3.2v-.6h3.3c.1 0 .1 0 .2.1l.8.8-.3.3v.2l-.8-.8z\"/><g><path d=\"M27.8 28.9c-.1 0-.1 0-.2-.1-.8-.8-4.3-1.4-8.4-1.4s-7.6.6-8.4 1.4c-.1.1-.3.1-.4 0s-.1-.3 0-.4c1.2-1.2 5.5-1.6 8.8-1.6s7.6.4 8.8 1.6c.1.1.1.3 0 .4 0 .1-.1.1-.2.1z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 38.3 38.3"
    }
  };
  _exports.default = _default;
});