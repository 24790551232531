define("ember-svg-jar/inlined/market-data/occupancy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n    <g transform=\"translate(2 2)\" fill=\"#64C5CC\" fill-rule=\"evenodd\">\n        <circle stroke=\"#64C5CC\" stroke-width=\"4\" fill-opacity=\".1\" cx=\"60\" cy=\"60\" r=\"60\"/>\n        <path d=\"M74.44 36.066c-.132-.33-.462-.593-.792-.725-.395-.066-.79-.066-1.055.132L53.868 47.407c-.593.395-.791 1.055-.527 1.714l.33.66-10.484 6.659a1.32 1.32 0 0 0-.462 1.714l.396.791-11.802 6.396c-.594.33-.857 1.12-.528 1.78L33.165 72c.198.462.66.725 1.187.725.197 0 .33-.066.527-.132l12.066-5.34.462.923c.197.461.659.791 1.186.791.132 0 .33 0 .462-.066l11.538-4.352.396.858c.198.461.66.79 1.187.79.132 0 .33 0 .461-.065l20.901-7.912c.33-.132.594-.396.726-.725.132-.33.132-.726-.066-1.055L74.44 36.066zM34.945 69.692l-1.253-2.637 10.484-5.736 1.648 3.494-10.879 4.88zM49.187 66l-3.627-7.912 9.231-5.868 4.616 9.89L49.187 66zm13.582-2.835l-6.527-14.11 16.55-10.484 8.439 17.67-18.462 6.924zm26.506-4.945c-.198.132-.396.132-.594.132-.461 0-.923-.264-1.186-.726l-12-25.055c-.33-.659-.066-1.45.593-1.78.66-.33 1.45-.066 1.78.594l12 25.055c.33.659.066 1.45-.593 1.78zM66.857 77.538v-2.11a3.968 3.968 0 0 0-3.956-3.955h-5.209a3.968 3.968 0 0 0-3.956 3.956v2.11l-10.022 10.22c-.527.527-.527 1.384 0 1.845.264.264.594.396.923.396.33 0 .66-.132.923-.396l10.088-10.22h3.495v9.297c0 .726.593 1.319 1.319 1.319.725 0 1.318-.593 1.318-1.319v-9.296h3.297l10.088 10.22c.264.263.593.395.923.395.33 0 .66-.132.923-.396.527-.527.527-1.384 0-1.846l-10.154-10.22zm-10.483-2.11c0-.725.593-1.318 1.318-1.318h5.275c.725 0 1.319.593 1.319 1.319v1.318h-7.912V75.43z\" fill-rule=\"nonzero\"/>\n    </g>\n",
    "attrs": {
      "width": "124",
      "height": "124",
      "viewBox": "0 0 124 124",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});