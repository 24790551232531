define("ember-svg-jar/inlined/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#010101\" d=\"M85 30H15C6.725 30 0 36.725 0 45v30h20v20h60V75h20V45c0-8.274-6.726-15-15-15zM70 85H30V60h40v25zm15-35c-2.773 0-5-2.225-5-5 0-2.774 2.227-5 5-5 2.77 0 5 2.225 5 5s-2.227 5-5 5zM80 5H20v20h60V5z\"/>",
    "attrs": {
      "version": "1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});