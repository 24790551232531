define("ember-svg-jar/inlined/icon-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M13.9688 2L7.98375 8.3L1.99875 2\" stroke=\"#BFBFBF\" stroke-width=\"2.01297\" stroke-linecap=\"square\"/>\n",
    "attrs": {
      "width": "16",
      "height": "10",
      "viewBox": "0 0 16 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});